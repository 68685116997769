// -------------------------------------------------------------------
// :: TESTIMONIALS
// -------------------------------------------------------------------


.rd-testimonials-header{
	text-align: center;
	padding: 40px 0 30px;
	border-bottom: 1px solid $secondary-color;
	margin-bottom: 30px;

	a, div{
		width: 100%;
		display: inline-block;
		margin: 0 2.75%;
		position: relative;

		.rd-headshot{
			position: relative;
			width: 100%;
			overflow: hidden;
			margin-bottom: 1em;
			img{
				width: 50%;
				height: auto;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
		h5{
			color: $font-color;
			text-transform: none;
			font-size: $font-size-medium;
			line-height: $font-size-xlarge;
			margin-top: 0;
			letter-spacing: 0;
			font-family: $font-family-text;
		}
		h6{
			color: $font-color;
		}
		&:first-child{
			margin-left: 0;
		}
		&:last-child{
			margin-right: 0;
		}

		&.rd-is-active{
			h5{
				color: $secondary-color;
			}
			&:after{
				content: "";
				display: block;
				width: 20px;
				height: 20px;
				background-color: #fff;
				border-left: 1px solid $secondary-color;
				border-top: 1px solid $secondary-color;
				position: absolute;
				bottom: -40px;
				left: 50%;
				margin-left: -10px;
				transform: rotateZ(45deg);
			}
		}
	}
}

.rd-testimonials-content{
	padding-bottom: 4rem;

	.rd-testimonial{
		display: none;
		p{
		  font-family: $font-family-text;
		  font-weight: 100;
		  font-size: $font-size-medium;
		  line-height: 1.5;
		}
		&.rd-is-visible{
			display: block;
			text-align: center;
		}
	}
}

.rd-custom-testimonial{
	h4, h5{
		padding-top: 1rem;
	}

	p{
		margin-top: 0;
	}

	.rd-custom-testimonial-header{
		border-right: 1px solid #039;
		padding-right: 1rem;

		h4, h5{
			padding-top: 0;
		}
	}

	.rd-custom-testimonial-text{
		p{
			font-family: "Roboto";
			font-weight: 100;
			font-size: 18px;
			line-height: 1.5;
		}
	}
}

.grid-item { width: 25%; }
.grid-item--width2 { width: 50%; }