@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";
@import "../base/_components_icons";

// -------------------------------------------------------------------
// :: NAVIGATION
// -------------------------------------------------------------------

// General

.rd-main-navigation, .rd-mobile-main-navigation{
    width: 76%;
    left: 12%;

    @include FLOW-at($desktop){
        width: 80%;
        left: 10%;
    }
}

// Mobile

.rd-mobile-navigation-block{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999999;

    #mobileMenuBtn{
        display: block;
        padding-left: 12px;
        padding-right: 12px;
    }

    .rd-logo-vub{
        display: block;
        float: left;
        background-image: none;
        height: 60px;
        padding-top: 2px;
        min-height: inherit;
        img{
            height: 56px;
        }
    }

    .rd-search-block{
        display: block;
        height: 60px;
        padding: 1em 20px;
        width: auto;

        span.icon-search{
            display: inline-block;
            margin: 0 auto;
        }
    }

    .rd-sub-search{
        display: none;
        position: absolute;
        z-index: 9999;
        top: 60px;
        width: 100%;
        overflow: visible;

        input.search-field{
            display: block;
            width: 70%;
            float: left;
            padding-left: 1em;
        }

        input.search-submit{
            width: 30%;
            display: block;
            color: $font-color;
            float: left;
            border-left: 1px solid $medium;
            background-color: $light-extra;
        }
    }

    .rd-sub-search.active{
        display: block;
    }

    .rd-menu-button{
        width: auto;
        float: right;
        padding-left: 0;
        display: inline-block;
        padding-top: .5em;
        cursor: pointer;
        border-left: 1px solid $medium;
    }

    .rd-info-block{
        display: block;
        height: 60px;
        padding: 1.3em 5px 0 15px;
        border-left: 1px solid $medium;
        border-right: none;

        &:after{
            background-color: $light;
        }
    }

    #langmenu.rd-sub-menu{
        display: none;
        position: absolute;
        top: 60px;
        z-index: 9999;
        width: 100%;
        background-color: $light;
        list-style: none;
        border-top: 1px solid $medium;

        li{
            a{
                display: block;
                text-align: center;
                padding: .5em;
            }
        }
    }

    #langmenu.rd-sub-menu.active{
        display: block;
    }

    nav#mobile_mainmenu.rd-mobile-main-navigation{
        display: none;
        transition: opacity 1s ease-out;
        opacity: 0;
        background-color: $light;
        border-top: 1px solid $medium;

        span.icon-arrow-down{
            display: none;
        }

        li.rd-menu-item{

            a{
                display: block;
                min-height: inherit;
                padding: 1em .3em;
                width: 100%;
                &:hover, &:after{
                    background-color: $light !important;
                    color: $font-color;
                }
            }

            ul.dropper--items{
                list-style: none;
                padding: 0;

                li{
                    margin: 0;

                    a{
                        color: $font-color;

                    }
                }
            }
        }

        &:hover{
            background-color: $light !important;
            color: $font-color;
            a{
                background-color: $light !important;
                color: $font-color;
            }
        }
    }

    nav#mobile_mainmenu.rd-mobile-main-navigation.active{
        opacity: 1;
        display: block;
    }

    @include FLOW-at($desktop) {
        display: none;
    }
}

// Main Menu

.rd-main-navigation-block{
    display: none;

    @include FLOW-at($desktop){
        display: block;
    }

    .rd-logo-vub{
        background-image: none;
        padding: .2em 0;
        height: 76px;
        display: block;
        width: 10%;
        z-index: 9999;

        @include FLOW-at($desktop-xl){
            width: 20%;
        }

        img{
            height: 100%;
        }

        img.desktop{
            display: none;
            @include FLOW-at($desktop-xl){
                display: block;
                padding-left: 2em;
            }
        }

        img.mobile{
            @include FLOW-at($desktop-xl){
                display: none;
            }
        }
    }

    .rd-menu-item{

        & > a{

            padding: 2em .5em;

            @include FLOW-at($desktop){
                height: 100%;
                padding: 2em .75em;
            }
        }
    }

    .rd-menu-item.current-menu-item{

        a{
            color: $orange;
        }
    }

    .rd-menu-item.dropper{

        position: relative;
        margin-right: 10px;

        span.icon-arrow-down{

            position: absolute;
            font-size: 26px;
            margin-left: 0;
            top: 22px;
            right: -15px;

            @include FLOW-at($desktop){
                top: 26px;
                //right: -7px;
            }
        }

        a{}&:hover{

             ul.dropper--items{
                 display: block;
             }
         }

        ul{
            display: none;
            position: absolute;
            list-style: none;
            background-color: $light-extra;
            width: 250px;
            padding: 0;

            li{
                margin: 0;
                padding: .8em .8em;
                text-align: left;
                border-top: 1px solid $light;

                a{
                    display: block;
                    color: $font-color;
                    width: 100%;
                    height: 100%;

                    span.icon-arrow-down{
                        display: none;
                    }

                }&:hover{
                     background-color: $light;
                 }

                span.icon-arrow-down{
                    display: none;
                }
            }
        }
    }
}

.rd-right-menu-block{
    width: 121px;
    border-left: none;
}

.rd-search-block{
    height: 100%;
    width: 50px;
    padding: 1.55em 0.4em;
    cursor: pointer;
    float: right;
    border-left: 1px solid $medium;

    .rd-sub-search{
        margin-right: -6px;
        margin-top: 1.5em;
        border-top: 1px solid $medium;

        input.search-field{
            display: block;
            width: 70%;
            float: left;
            padding-left: 1em;
        }

        input.search-submit{
            width: 30%;
            display: block;
            color: $font-color;
            float: left;
            border-left: 1px solid $medium;
            background-color: $light-extra;
        }
    }
}

// This is now the language block

.rd-info-block{
    height: 100%;
    width: 70px;
    float: right;
    padding: 1.9em 5px 0 15px;
    cursor: pointer;
    border-left: 1px solid $medium;
    border-right: none;

    .rd-sub-menu{

        opacity: 0;
        transition: opacity 0.5s linear;

        li{
            a{
                span{
                    display: block;
                    font-size: 1em;
                    padding: 1em 0;
                }
            }
        }
    }

    &:hover{

        .rd-sub-menu{
            opacity: 1;
        }
    }
}

// Secondary Menu

nav.rd-secondary-navigation{

    .rd-navigation-holder{

        ul.rd-navigation-inner{

            border-bottom: none;

            @include FLOW-at($desktop){

                display: inline-block;
                border-bottom: 1px solid $medium;
            }

            li.page_item.current_page_item, li.page_item{

                margin: 0;

                a{
                    padding: .5em;

                    @include FLOW-at($desktop){
                        padding: .8em;
                    }
                }

                @include FLOW-at($desktop){

                    float: left;
                    margin: 0 20px;

                    &:first-child {
                        margin-left: 0;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            li.page_item.current_page_item{

                a{
                    color: $secondary-color;
                    &:after {
                        height: 0;
                        background-color: $light;
                        bottom: 0;
                    }

                    @include FLOW-at($desktop){

                        &:after{
                            height: 2px;
                            background-color: $orange;
                        }
                    }
                }
            }
        }
    }
}