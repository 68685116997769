// -------------------------------------------------------------------
// :: FOOTER
// -------------------------------------------------------------------
@import "../_globals";
@import "../base/_layout";
@import "../base/_components_icons";
@import "../utils/_media-queries";
@import "../utils/_functions";

footer{

    .rd-container.rd-has-default-margins{

        display: block;

        @include FLOW-at($desktop){
            padding-left: 0;
        }

        .rd-footer-navigation{
            display: block;
            width: 100% !important;
            padding: 0;
            margin: 0 0 2em 0;

            .widget.rd-col-1-4{
                display: block;
                padding-bottom: 1.5em;
                text-align: center;
                margin: 0;

                @include FLOW-at($desktop){
                    width: auto;
                    text-align: left;
                }
            }

            @include FLOW-at($desktop){
                width: 72.625% !important;
                float: right !important;
            }
        }

        .rd-contact-info{
            display: block;
            margin: 0 auto;
            max-width: 250px;
            float: none;
            padding: 0;

            @include FLOW-at($desktop){
                max-width: none;
                float: left !important;
                margin: 0;
                padding: 0;
                width: 22.875% !important;
            }
        }
    }
}