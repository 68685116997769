// -------------------------------------------------------------------
// :: CONTENT SECTIONS
// -------------------------------------------------------------------


.rd-content{
	text-align: center;
	.rd-content-holder{
		margin: 0 auto;
		width: 50%;
		max-width: 630px;
		text-align: justify;
		position: relative;
		h2{
			text-align: center;
			margin-bottom: 1em;
		}
		p + h2{
			margin-top: 3em;
		}
		.rd-cols{
			margin-top: 3em;
			text-align: left;
		}
	}
	&[class*="rd-extra"]{
		.rd-content-extra{
			width: 22%;
			position: absolute;
			top: 3.2rem;
			text-align: left;
		}
	}
	&.rd-extra-left .rd-content-extra{
		left: 0;
	}
	&.rd-extra-right .rd-content-extra{
		right: 0;
	}
	img{
		display: block;
		width: 100%;
		height: auto;
	}	
	img + small{
		margin-top: 1em;
	}
}

.rd-content-extra{
	blockquote{
		padding-left: 1.2em;
		border-left: 1px solid $primary-color;
	}	
}

.rd-intro{
	padding: 4em 0 3em;
	.rd-container{
		padding-bottom: 0;
	}
	.rd-content-holder{
		text-align: center;
		p{
			font-weight: 500;
		}
		.rd-link-more{
			margin-top: 1em;
			display: inline-block;
		}
		.rd-link-more span{
			font-size: 2.2em;
			top: -5px;
		}
	}
}


.rd-embed{
	position: relative;
	padding-bottom: 35%;
	height: 0px;
	width: 65%;
	margin: 2em auto;
	iframe{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}


.rd-flickr .rd-embed{
	width: 90%;
	padding-bottom: 60%;
}