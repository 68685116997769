// -------------------------------------------------------------------
// :: FONT-FACE
// -------------------------------------------------------------------
// Note: promote the use of system-fonts where possible
//
// - http://css-tricks.com/forums/topic/do-we-really-need-ttf-svg-in-font-face-now
// - http://www.fontsquirrel.com/tools/webfont-generator
// - http://practicaltypography.com/system-fonts.html
// - http://www.google.com/fonts
//
// - Adding .woff2 support (note: for now this is a manual process)
// - https://gist.github.com/sergejmueller/cf6b4f2133bcb3e2f64a
// - https://everythingfonts.com/ttf-to-woff2/
//
// - UPDATE 05/2015
// - https://css-tricks.com/snippets/css/using-font-face/

@mixin FLOW-get-font($_name, $_url, $_weight: normal, $_style: normal) {

	@font-face {
		font-family: $_name;
		//src: url($_url +".eot"); // enable .eot support for <IE9
		src: url($_url +".eot?#iefix") format("embedded-opentype"),
             //url($_url +".woff2") format("woff2"),
		     url($_url +".woff") format("woff"),
		     url($_url +".ttf") format("truetype"),
		     url($_url +".svg#icon") format("svg");
		font-weight: $_weight;
		font-style: $_style;
	}

}
