// -------------------------------------------------------------------
// :: FAQ
// -------------------------------------------------------------------

.rd-faq {

  h1,
  h2,
  h3 {
    font-family: "Adventor" !important;
    text-transform: none !important;
    color: #003399;
    margin-top: 10%;
  }

  .faqHeader {
    font-size: 27px;
    margin: 20px;
  }

  .panel-heading {
    background-color: #ffffff !important;
    position: relative;

    a {
      &,
      &:focus,
      &:hover {

      }
      color: #003399;
      font-family: 'Roboto';
      font-weight: 300;
      word-wrap: break-word;
      text-decoration: none;
    }

    span:first-child {
      width: 90%;
      display: block;
    }

    span:last-child:before {
      font-family: "icons";
      content: '\e003'; /* "play" icon */
      color: #F58724;
      font-size: 30px;
      line-height: 22px;

      position: absolute;
      top: 50%;
      right: 2%;
      -webkit-transform: translate(0, -50%) rotate(90deg);
      -moz-transform: translate(0, -50%) rotate(90deg);
      -ms-transform: translate(0, -50%) rotate(90deg);
      -o-transform: translate(0, -50%) rotate(90deg);
      transform: translate(0, -50%) rotate(90deg);
      /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
      color: #003399;
    }

    &.toggle{
      span:last-child:before {
        font-family: "icons";
        content: '\e003'; /* "play" icon */
        color: #F58724;
        font-size: 30px;
        line-height: 22px;

        position: absolute;
        top: 50%;
        right: 2%;
        transform: translate(0, -50%) rotate(-90deg);
        -webkit-transform: translate(0, -50%) rotate(-90deg);
        -ms-transform: translate(0, -50%) rotate(-90deg);
        -moz-transform: translate(0, -50%) rotate(-90deg);
        -o-transform: translate(0, -50%) rotate(-90deg);
        float: right;
      }
    }

  }

  .panel-body{
    a{
      word-wrap: break-word;
    }
  }
}

ul{
  margin-bottom:20px;
}

