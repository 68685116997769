// -------------------------------------------------------------------
// :: TEAM
// -------------------------------------------------------------------

.rd-team{
	border-top: 1px solid $border-color;
	position: relative;
	z-index: 99;
	margin-top: 60px;
}

.rd-team-member{
	border-bottom: 1px solid $border-color;
	display: block;
	&:nth-child(n + 8){
		display: none;
	}


	.rd-member-intro{
		display: none;
	}
	&.rd-is-open{
		.rd-member-top a{
			color: $secondary-color;
			transform: rotateZ(180deg);
		}
		.rd-member-intro{
			display: block;
		}
	}
}

.rd-member-top{
	position: relative;
	padding: 17px 20px 20px 20px;
	text-align: center;
  min-height: 70px;
	word-break: break-word;
	.rd-headshot{
		width: 33%;
		overflow: hidden;
		position: relative;
		margin: 5px auto 15px;
		&:before{
			display: block;
			content: "";
			width: 100%;
			padding-top: 100%;
		 }
		img{
			@include spread();
            object-fit:cover;
		}
	}
	h5{
		color: $secondary-color;
		text-transform: none;
		font-size: $font-size-medium;
		line-height: $font-size-xlarge;
		margin-top: 0;
		letter-spacing: 0;
		font-family: $font-family-text;

	}
	p{
		margin: 0;
		font-size: $font-size-small;
	}
	a{
		position: absolute;
		right: -17px;
		top: 50%;
		font-size: 50px;
		margin-top: -42px;
		color: $border-color;
		&:hover{
			color: $primary-color;
		}
	}
}

.rd-team-pagination{
	a[class*="arrow"]{
		font-size: 3em;
		width: 48px;
		float: left;
		&.rd-next{
			float: right;
			margin-right: -15px;
		}
		&.rd-previous{
			margin-left: -15px;
		}
		&:hover{
			color: $primary-color;
		}
		&.rd-inactive{
			color: $border-color;
		}
	}
	.rd-dots{
		width: calc(100% - 98px);
		margin-left: 15px;
		float: left;
		text-align: center;
		padding-top: 30px;
		a.rd-dot{
			display: inline-block;
			margin: 0 0.2em;
			height: 10px;
			width: 10px;
			border: 2px solid $primary-color;
			border-radius: 100%;
			transition:all 0.5s ease-in;
			&.rd-is-active{
				background-color: $primary-color;
			}
		}
	}
}

.rd-member-intro{
	padding-bottom: 50px;
	a.rd-read-more{
		clear: both;
		float: right;
		color: $primary-color;
		line-height: 35px;
		[class*="icon"]{
			font-size: 30px;
			line-height: 0;
		    margin-top: 3px;
		    display: block;
		    float: right;
		}
	}
}


@include FLOW-at($desktop-xl) {
	.rd-member-top{
		padding-left: 90px;
		text-align: left;
		.rd-headshot{
			position: absolute;
			left: 0;
			top: 15px;
			width: 70px;
		}
	}
}

.people-filter{
	transition: background 0.4s, box-shadow 0.4s;

	a{
	overflow: hidden;
	display: block;
	position: relative;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;

		img{
			visibility: hidden;
			width: 100%;
		}

		span{
			position: absolute;
			width: 100%;
			height: auto;
			min-height: 60px;
			background-color: transparentize($secondary-color, .2);
			display: flex;
			transition: min-height 0.35s;
		}

		&:hover{
			span{ min-height: 100%;	}
		}

		h4{
			color:#fff !important;
			margin: auto;
			padding: .5rem 1rem;
			z-index: 2;
			font-size: 1rem;
		}

		.bottom{
			bottom: 0;
		}
	}
}
