// -------------------------------------------------------------------
// :: SECTIONS
// -------------------------------------------------------------------


.rd-section-header{
	@include clearfloat();
	padding-bottom: 2.5em;
	h2{
		float: left;
	}
	.rd-section-more{
		float: right;
		@extend .rd-link-more;
	}
}

section.rd-light-bg{
	background-color: $light;
}