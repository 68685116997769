.caldera-grid{
  max-width: 400px;
  margin: 0 auto;

  .form-group input, .form-group textarea{
    font-size: .875rem;
    padding: .9em .8em .8em;
    background-color: $light;
    border: 1px solid $medium;
    border-radius: 0;
    width: 100%;
    transition: border 100ms ease-in-out;

    &:focus{
      background-color: $light-extra;
      outline: none !important;
      border: 1px solid $medium;
      box-shadow: none;
    }
  }

  .form-group .btn-default{
    color: $light-extra;
    background-color: $orange;
    border: 1px solid $orange;
    padding: 0.6em 3em;

    &:active, &:focus, &:hover, &:visited{
      background-color: $ui-orange !important;
      border-color: $ui-orange;
      box-shadow: none;
      cursor: pointer;
      color: $light-extra;
      outline: none !important;
    }
  }

  .col-sm-6{
    width: 48%;
  }
}