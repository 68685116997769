// -------------------------------------------------------------------
// :: TABLES - #TBD
// -------------------------------------------------------------------

table {
  width: 100%;
  border-spacing: 0;
  //table-layout: fixed;
  margin: 0;
  
  border-collapse: collapse;
  //background: rgba($black, 0.01);
  //border-bottom: 1px solid $border-color;
}

// Dropdown menus and checkboxes in tables
// shouldn't have additional margin values

table .input,
table [class*='input__'] {
  margin: 0;
}

table select {
  font-weight: 400;
  border-color: transparent !important;
}

table select + [class*='icon'] {
  padding-top: 0.15em;
}


// -------------------------------------------------------------------
// :: TYPOGRAPHIC TABLE SCALE
// -------------------------------------------------------------------
// Table padding should follow a typographic scale
// - default height: 52px @ $base-font-size of 16px
// - default height: 50px @ $base-font-size of 15px
.rd-table{
	th:not(.has-no-padding),
td:not(.has-no-padding) {
  padding: 0.9rem 0.7rem;
}

}



// -------------------------------------------------------------------
// :: TABLE HEADERS
// -------------------------------------------------------------------

th:not(.is-empty) {
  font-size: rem($font-size-small);
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background: $secondary-color;
  color: $light-extra;
  border-right: 1px solid rgba(255,255,255,0.2);
  border-bottom: 1px solid $secondary-color;
  &:last-child{
	  border-color: $secondary-color;
  }
  .input select{
	  background-color: transparent;
	  border: 0;
	  color: $light-extra;
	  box-shadow: none;
	  &.has-no-value{
		  color: $light-extra;
	  }
	  & + [class*='icon'],
	  &.has-no-value + [class*='icon']{
		  color: $light-extra;
		  font-size: 1.5em;
		  margin: 0.13rem 0.5rem;
	  }
  }
}



// -------------------------------------------------------------------
// :: TABLE DATA
// -------------------------------------------------------------------

tr:nth-child(even) {
  background: $light;
}
td {
  font-weight: 300;
  vertical-align: middle;
  position: relative;
  font-weight: 100;

  // Add a transparant border to
  // enforce the default height as
  // set in the typographic scale

  border: 1px solid $border-color;
  padding: 0 2rem;

  .input{
	  label{
		  width: 40%;
		  float: left;
		  padding: 0.6rem 0rem 0.40rem;
	  }
	  div{
		  width: 59%;
		  float: right;
	  }
	  &.text-right input{
	  	text-align: right;
	  }
	  &.text-primary-color input{
	  	color:$primary-color;
	  }
  }
}


// -------------------------------------------------------------------
// :: TABLE WITH ACTIONS
// -------------------------------------------------------------------
// Action buttons should be placed in the last column
// and should extend the button-flat class
//
// Note that this is adding basic styling to table actions and
// needs furhter adjustments depending on the context/project
/*

.has-actions th:last-child {
  width: rem(96px);
}

.has-actions td:last-child {
  vertical-align: middle;
}

.has-actions [class*='button'] {
  font-size: 0.9rem;
  border-radius: 0;
}
*/


// -------------------------------------------------------------------
// :: TABLE WITH CHECKBOXES/RADIO INPUT
// -------------------------------------------------------------------
// Checkboxes and radios should be placed in the first column

/*
.has-select-options th:first-child {
  @if $base-font-size == 16px { width: rem(52px); }
  @if $base-font-size == 15px { width: rem(50px); }
}

.has-select-options td:first-child {
  vertical-align: middle;
}

.has-select-options [class*='icon'] {
  width: rem(20px);
  height: rem(20px);
  margin-left: rem(14px) !important;
}

.has-select-options .input__checkbox [class*='icon']:before {
  font-size: 2rem;
  left: rem(-6px);
  top: rem(-7px);
}

.has-select-options .input__radio [class*='icon']:before {
  width: rem(10px);
  height: rem(10px);
  margin: rem(4px);
}
*/
