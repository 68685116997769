@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// -------------------------------------------------------------------
// :: CONTENT - SINGLE
// -------------------------------------------------------------------

body.search, body.search-results{

    article.post{
        display: block;
        width: 100%;
        margin-bottom: 3em;
    }
}

