// -------------------------------------------------------------------
// :: CONTACT SECTION
// -------------------------------------------------------------------

section.rd-contact{
	.rd-container{
		max-width: 65em;
		padding: 4rem 0 0;
	}
	.rd-col-2-3 .rd-cols{
		margin-bottom: 2em;
		.rd-col-2-3{
			padding-left: 2em;
		}
	}
}