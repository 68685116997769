// -------------------------------------------------------------------
// :: BUTTONS
// -------------------------------------------------------------------
// Use hyperlinks for navigation, buttons for everything else
// Form buttons (input[type="submit", reset, ...) are normalized
// in base/_forms.scss, but use .button classes for styling

@include FLOW-normalize-buttons();


// -------------------------------------------------------------------
// :: PRIMARY BUTTON
// -------------------------------------------------------------------
// Solid button with full background color

@mixin FLOW-button($_color: $light-extra, $_background: $primary-color, $_border: $primary-color) {

  color: $_color;
  background-color: $_background;
  font-size: $font-size;
  border: 1px solid $_background;

  // States

  &:not([disabled]):hover {
    background-color: darken($_background, 10%);
    border-color: darken($_background, 10%);
    box-shadow: none;
    cursor: pointer;
  }

  &:not([disabled]):active {
    background-color: lighten($_background, 10%);
    border-color: lighten($_background, 10%);
  }

  &[disabled] {
    background-color: $font-color-medium;
    border-color: $font-color-medium;
  }

}


// -------------------------------------------------------------------
// :: SECONDARY/FLAT BUTTON
// -------------------------------------------------------------------
// Outlined button with border and transparent background

@mixin FLOW-button-secondary($_color: $secondary-color, $_background: $light-extra) {

  color: $_color;
  background: $_background;
  border: 1px solid $_color;

  // States

  &:not([disabled]):hover {
	color: $light-extra;
	background-color: $_color;
    cursor: pointer;
  }

  &:not([disabled]):active {
    background: darken($_background, 5%);
  }

  &[disabled] {
    color: $font-color-medium;
  }

}


// -------------------------------------------------------------------
// :: GENERAL BUTTON CLASS
// -------------------------------------------------------------------

[class*='rd-button']{
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  border-radius: $border-radius;

  transition: all 100ms ease-in-out;
  padding: 0.6em 1.2em;
}

[class*='rd-button'].rd-button__back {
  position: absolute;
  padding: 1.3em 0;
  width: 4.5em;
  top: 0px;
  box-sizing: border-box;
  height: 61px;

  @include FLOW-at($desktop) {
	  bottom: -1px;
	  height: auto;
  }
}

// -------------------------------------------------------------------
// :: DECORATED BUTTONS
// -------------------------------------------------------------------
// Decorated buttons with icons before or after a text label

[class*='rd-button'] [class*='icon'] {
  display: inline-block;
  margin: -0.4rem -0.5em 0;
}

// Size & positioning
// inside buttons

[class*='rd-button'] [class*='icon']:before {
  position: relative;
  font-size: 2.7em;
  line-height: 0;
}

[class*='rd-button'] span + [class*='icon'],
[class*='rd-button'] [class*='icon'] + span {
  margin-left: 1em;
}

[class*='rd-button'] .icon-checkmark:before{
	font-size: 1.5em
}

// -------------------------------------------------------------------
// :: BUTTON CLASSES
// -------------------------------------------------------------------
// Primary buttons - for primary navigation and key-interactions


.rd-button {
  @include FLOW-button();
}

.rd-button-secondary {
  @include FLOW-button-secondary($_background: transparent);
  font-weight: 500;
  margin: 0 .5em .5em 0;

  &.is-checked{
    color: $light-extra;
    background-color: $secondary-color;
  }
}

.button-bottom{
  vertical-align: bottom;
}

.filters-button-group{
  .rd-button-secondary{
    display: inline-block;
    width: 100%;

    @include FLOW-at($tablet) {
      width: auto;
    }
  }
}


// Flat buttons - for small in-line
// interactions (eg. form reset button)

//.button-flat {
//  @include FLOW-button-secondary($_color: $font-color-medium, $_background: transparent, $_with-border: false);
//}


// -------------------------------------------------------------------
// :: ROUND BUTTONS
// -------------------------------------------------------------------
// Round buttons extend base button styles
// by adding a border-radius value

//.is-rounded {
//  border-radius: 50%;
//  position: relative;
//}

// Round buttons with
// text labels beneath
// Quick prototype

//.is-rounded span:not([class*='icon']) {
//  width: 200%;
//  transform: translate(-50%, 150%);
//  position: absolute;
//  bottom: 0;
//  left: 50%;
//}


// -------------------------------------------------------------------
// :: BUTTON GROUP
// -------------------------------------------------------------------

//.button-group *:not(:first-child) [class*='button'] {
//  border-left: none;
//}

//.button-group [class*='button'] {
//  padding-right: 1.2rem;
//  padding-left: 1.2rem;
//  border-radius: 0;
//}

//.button-group *:first-child [class*='button'] {
//  border-bottom-left-radius: $border-radius;
//  border-top-left-radius: $border-radius;
//}

//.button-group *:last-child [class*='button'] {
//  border-bottom-right-radius: $border-radius;
//  border-top-right-radius: $border-radius;
//}