@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// -------------------------------------------------------------------
// :: WIDGETS
// -------------------------------------------------------------------

// SIDEBAR WIDGETS (GENERAL)

section.widget{
    display: block;
    margin-bottom: 10px;
    clear: both;

    h3{
        display: block;
        margin-bottom: 3px;
    }
}

// SEARCH

section.widget_search{

    form.search-form{
        display: block;

        @include FLOW-at($desktop){
            display: inline-block;
        }

        label{
            display: block;
            float: left;
            width: 70%;

            span.screen-reader-text{
                display: none;
            }

            input.search-field{
                display: block;
                border-right: 0;
            }
        }

        input.search-submit{
            display: block;
            width: 30%;
            float: left;
        }
    }
}