// -------------------------------------------------------------------
// :: LOGO - #TBD
// -------------------------------------------------------------------

.rd-logo {
  width: 95px;
  display: inline-block;

  @include FLOW-at($desktop) {
    width: 144px;
  }
}

.rd-logo img {
  display: block;
}

.logo {
  fill: $primary-color;

  height: 3em; // 48px @ 16px basefont
  display: inline-block;
  position: relative;
  text-align: left;
}

.logo svg {
  height: 100%;
}

.logo__mark path:nth-child(1),
.logo__typo path:nth-child(1),
.logo__typo path:nth-child(2) {
  //fill: $brown;
}

.logo__label {
  font-weight: 300;
  line-height: 1.25;
  color: $font-color-medium;
  text-transform: uppercase;
  transform: translateY(-50%);
  position: absolute;
  left: 3.25em;
  top: 50%;
}

