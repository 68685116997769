// -------------------------------------------------------------------
// :: TILE
// -------------------------------------------------------------------


.rd-tile{
	height: 280px;
	border-radius:$border-radius;
	overflow: hidden;
	box-sizing: border-box;
	background-color: $light-extra;
	&.rd-hero{
		.rd-v-center-inner{
			padding: 1.5em;
		}
		.rd-button{
			border-radius:$border-radius;
		}
	}
	&:not(.rd-hero){
		text-align: center;
		border: 1px solid $medium;
		padding: 1.5em;
	}
	form{
		margin-top: 2em;
	}
	input[type="submit"]{
		width: 100%;
		margin-top: 0.5em;
	}
	hr{
		width: 50%;
		margin: 1em auto;
	}
	h2{
		color: $secondary-color;
		letter-spacing: 0;
		font-weight: 700;
	}
	h6{
		color: $font-color-medium;
	}
}