// -------------------------------------------------------------------
// :: GLOBAL SETTINGS
// -------------------------------------------------------------------

// Debug breakpoints
// - see utils/_media-queries.scss

$debug-mode: false;


// -------------------------------------------------------------------
// :: MEDIA QUERIES
// -------------------------------------------------------------------
// Major breakpoints should be set in base/_typography.scss
// Minor breakpoint should be added where needed (inline)

$mobile:     30em; // 480px
$tablet:     45em; // 720px
$desktop:    60em; // 960px
$desktop-xl: 71.25em; // 1140px


// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------
// See styleguide.html for more info on how colors are being used

$dark-extra:    #20262F;
$dark:       	#1e1e1e;
$medium:       	#e1e1e1;
$light: 		#F7F9FB;
$light-extra:   #fff;


$orange:   #ff6600; 
$blue:     #003399;

$primary-color: $orange;
$secondary-color: $blue;

$font-color:          #464646; // Base
$font-color-medium:   #7e8998; // Secondary (eg. image caption)
$font-color-light:    $light; // Placeholders in forms
$font-color-disabled: rgba(#1E2F41, 0.30); // Disabled Text

$background: #F7F9FB;

$border-color: #D9E5F6; // Borders and rules
$border-color-active: #93ADD1; // Active border


$ui-red:    #c93f30;
$ui-orange: #e37224;
$ui-green:  #2a8b3d;
$ui-blue:   #2b77b2;


// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------
// Base-font-sizes should be set in base/_typography.scss
//
// - http://www.awayback.com/revised-font-stack
// - http://practicaltypography.com/system-fonts.html

//$font-family-sans: "Fresco Sans", sans-serif;
//$font-family-serif: "Fresco", serif;

$font-family-title: "Adventor";
$font-family-text: "Roboto";

$font-size-xxxlarge: 65px; // h1
$font-size-xxlarge:  30px; // h2
$font-size-xlarge:   26px; // h3
$font-size-large:    22px; // h4
$font-size-medium:   20px; // h4
$font-size:          16px; // p
$font-size-small:    14px; // h5, small, cite
$font-size-xsmall:   12px; // top menu

// Default border-radius value for input elements,
// alerts, buttons, images (in <figure>), ...

$line-height:   1.7;
$border-radius: 0px; // 5px @ 16px
$box-shadow: 0px 2px 0px 0px rgba($border-color-active,0.2);

// -------------------------------------------------------------------
// :: FLOW IMPORTS
// -------------------------------------------------------------------

@import "utils/_css-shapes";
@import "utils/_font-face";
@import "utils/_functions";
@import "utils/_input-placeholder";
@import "utils/_media-queries";