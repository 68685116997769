// -------------------------------------------------------------------
// :: ALERTS
// -------------------------------------------------------------------

.rd-alert {
  color: $light-extra;
  border: 1px solid rgba($dark,0.2);
  padding: 0.907rem;
  p{
	  margin: 0;
  }
}

.rd-alert [class*='icon']:before {
  font-size: 1.65em;
  line-height: 0;
  position: relative;
  margin-right: 0.3em;
  top: -0.065em;
}

.rd-alert a {
  color: inherit;
}


// -------------------------------------------------------------------
// :: ALERT CLASSES
// -------------------------------------------------------------------

.rd-alert:not(.is-error):not(.is-warning):not(.is-success):not(.is-information):not(.has-no-background) {
  background: $light;
  color: $font-color-medium;
}

.rd-alert.is-error {
  background: $ui-red;
}

.rd-alert.is-warning {
  background: $ui-orange;
}

.rd-alert.is-success {
  background: $ui-green;
}

.rd-alert.is-information {
  background: $ui-blue;
}

