// -------------------------------------------------------------------
// :: MEDIA QUERIES
// -------------------------------------------------------------------
// 1) Using 'gulp-combine-media-queries' to combine and export
// media queries into a separate file (main.responsive.css)
// So use media queries wherever you need them (inline)
//
// Note: currently 'gulp-combine-media-queries' has no option
// to exclude breakpoints (eg. breakpoints for mobile-landscape)
// and will export everything to a single file (main.responsive.css)
// It would be nice to have this kind of functionality in there
// so we can serve tablet and desktop styles async when needed
// Note: this is possible now if we give up on breakpoints
// for mobile-landscape
//
// - http://github.com/konitter/gulp-combine-media-queries/issues/6
// - http://bitbucket.org/gwenvanhee/flow-gulp/issue/1/load-additional-css-async
//
// 2) When using 'respond.js' to enable media queries on <IE9
// you can only use min-width and max-width media queries
//
// - https://github.com/scottjehl/Respond

@mixin FLOW-at($_min-width) {
	@media screen and (min-width: $_min-width) {

		@content;

		// Show feedback on the current breakpoint if
		// $debug-mode is set true (shown in px and em)
		// Note: only applied to the html-element

		@if $debug-mode and inspect(nth(&, 1)) == "html" {
			&:before {

				$_val: strip-unit($_min-width);
				$_px: $_val * 16;
				$_col: $_val * 3.25;

				content: 'BREAKPOINT\A #{$_min-width} - #{$_px}px';
				background: rgba($_col, 0, 255-$_col, 0.7);
				font-family: sans-serif;
				white-space: pre;
				font-size: 11px;
				line-height: 1;
				padding: 0.5em;
				color: #FFF;

				position: fixed;
				z-index: 999999;
				top: 40px;
				right: 0;

			}
		}
	}
}

@mixin FLOW-to($_max-width) {
	@media screen and (max-width: $_max-width) {
		@content;
	}
}



