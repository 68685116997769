@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// -------------------------------------------------------------------
// :: GENERAL
// -------------------------------------------------------------------

// Content without sidebar

body.sidebar-primary{

    .rd-container.rd-has-default-margins{

        .main{
            width: 100%!important;

            @include FLOW-at($tablet){
                width: 64.332%!important;
                padding-right: 2em;
            }
        }
    }
}

.rd-container.rd-has-default-margins{

    .no-sidebar{

        #main-content-wrapper{
            display: block;
            margin-left: auto;
            margin-right: auto;

            @include FLOW-at($tablet){
                padding-right: 2%;
            }

            #main-inner-content{
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 820px;
            }
        }
    }

    padding-left: 0 !important;

    @include FLOW-at($tablet){
        padding-left: 2% !important;

    }
}

.rd-section-header{

    @include FLOW-at($tablet){
        display: block;
        padding-right: 2%;
    }
}

[class*="rd-col-"]:last-child{
    margin-right: 0 !important;
}

main{

    article{

        time{
            display: block;
            margin-bottom: 1em;
        }
    }

    .content-wrapper{
        display: block;
        width: 100%;
    }

    .nav-links{

        .nav-next, .nav-previous{
            display: inline-block;
            margin-right: 1rem;

            a{
                transition: all 300ms ease-in-out;
                background-color: $secondary-color;
                color: #fff;
                border: 1px solid transparent;
                padding: .5rem 1rem;
            }

            &:hover{
                a{
                    transition: all 300ms ease-in-out;
                    background-color: #fff;
                    color: $secondary-color;
                    border: 1px solid $secondary-color;
                }
            }
        }
    }
}

main.no-sidebar{
    margin-bottom: 3em;
}

.clear{
    clear: both;
}

// -------------------------------------------------------------------
// :: HOMEPAGE
// -------------------------------------------------------------------

body.home{

    .rd-spotlight{
        text-align: center;

        ul.rd-container{
            display: block;

            li{
                display: inline-block;
                float: none;
                margin: 0;

                a{
                    display: block;
                }
            }
        }

        a{
            &:after{
                background: url(../images/layout/triangle.svg) no-repeat right ;
            }
        }
    }
}

body.home.spotlight-count-1{

    .rd-spotlight{
        bottom: -9em;

        @include FLOW-at($tablet){
            bottom: -9em;
        }
    }

    #main-content-wrapper{
        margin-top: 7em;

        @include FLOW-at($tablet){
            margin-top: 8em;
        }
    }
}

body.home.spotlight-count-2{

    .rd-spotlight{
        bottom: -19em;

        @include FLOW-at($tablet){
            bottom: -9em;
        }
    }

    #main-content-wrapper{
        margin-top: 17em;

        @include FLOW-at($tablet){
            margin-top: 8em;
        }
    }
}

body.home.spotlight-count-3{

    .rd-spotlight{
        bottom: -30em;

        @include FLOW-at($tablet){
            bottom: -9em;
        }
    }

    #main-content-wrapper{
        margin-top: 29em;

        @include FLOW-at($tablet){
            margin-top: 8em;
        }
    }
}

body.home.spotlight-count-4{

    .rd-spotlight{
        bottom: -42em;

        @include FLOW-at($tablet){
            bottom: -20em;
        }

        @include FLOW-at($desktop){
            bottom: -9em;
        }
    }

    #main-content-wrapper{
        margin-top: 41em;

        @include FLOW-at($tablet){
            margin-top: 19em;
        }

        @include FLOW-at($desktop){
            margin-top: 8em;
        }
    }
}

#latest-news{

    margin-top: 3em;

    .rd-section-header{

        h2{
            display: block;
            width: 100%;
            margin-bottom: .5em;

            @include FLOW-at($tablet){
                display: inline-block;
                width: auto;
                margin-bottom: 0;
            }
        }

        a.rd-section-more{
            display: block;
            float: left;
        }
    }
}

.rd-margin-bottom{
    margin-bottom: 2rem;
}

.rd-padding-bottom{
    padding-bottom: 1rem;
}

.rd-padding-top{
    padding-top: 2rem;
}