// -------------------------------------------------------------------
// :: SPOTLIGHT
// -------------------------------------------------------------------

header .rd-spotlight{
	position: absolute;
    bottom: -42em;
	left: 0;
	z-index: 9;
         @include FLOW-at($desktop){
            bottom: -9em;
    }    
     @include FLOW-at($tablet){
            bottom: -20em;
    }


}

.rd-spotlight{
	width: 100%;
	.rd-container{
		background-color: $light-extra;
		padding: 2px;
	}
	a{
		@include cover();
		height: 11em;
		position: relative;
		text-decoration: none;
		border: 3px solid $light-extra;
        overflow: hidden;
		
		&:before{
			content: "";
			display: block;
			@include spread();
			
			background-color: rgba($dark,0.15);
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 38%, rgba(0,0,0,0.7) 100%); 
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 38%,rgba(0,0,0,0.7) 100%); 
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.2) 38%,rgba(0,0,0,0.7) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 ); 
		}
		&:after{
			
			content: "";
			display: block;
			@include spread();
			background-color: rgba($primary-color,0.8);
			transition:all 0.2s ease-in;
			opacity: 0;
            background: url(../img/layout/triangle.svg) no-repeat right ;
			-webkit-transform: rotate3d(0,0,1,180deg);
			transform: rotate3d(0,0,1,-40deg);
			-webkit-transform-origin: 0 0%;
			transform-origin: 0 0%;
		}
		[class*="icon"]{
			color: $light-extra;
			font-size: 7em;
			z-index: 9;
			text-align: center;
			margin-top: -40px;
			@include spread();
		}
		h4{
			color: $light-extra;
			position: absolute;
			z-index: 2;
			bottom: 0;
			left: 0;
			padding: 1em;
			width: 100%;
			text-align: center;
		}
		&:hover{
			&:after{
				opacity: 0.8;
                	-webkit-transform: rotate3d(0,0,1,0deg);
	transform: rotate3d(0,0,1,0deg);
			}	
		}
	}
}

