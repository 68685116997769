// -------------------------------------------------------------------
// :: FOOTER
// -------------------------------------------------------------------
@import "../_globals";
@import "../base/_layout";
@import "../base/_components_icons";
@import "../utils/_media-queries";
@import "../utils/_functions";

footer {
    background-color: $dark;
    color: $light-extra;
    .rd-container {
        font-size: rem(16px);

        //        TYPOGRAPHY START
        h1 {
            @include FLOW-at($tablet) {
                font-size: rem($font-size-xxxlarge);
            }
            font-size: rem(45px);
            line-height: 1.025;
            letter-spacing: 2px;
        }

        h2 {
            @include FLOW-at($tablet) {
                font-size: rem($font-size-xxlarge);
            }
            font-size: rem($font-size-xlarge);
            font-weight: bold;
            line-height: 1.025;
            letter-spacing: 1px;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        h3 {
            font-size: rem($font-size-xlarge);
            font-family: "Roboto Condensed";
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1.45;

        }

        h4 {
            font-size: rem($font-size-large);
            line-height: 1.45;

        }

        h5 {
            font-size: rem($font-size);
            line-height: 1.55;
            font-family: "Roboto Condensed";
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $light-extra;
        }

        h6 {
            line-height: 1.55;
            font-size: rem($font-size-small);
            font-weight: 200;
            text-transform: uppercase;
            font-family: "Roboto Condensed";
            letter-spacing: 1px;
            sup {
                font-size: 9px;
            }
        }
        //        TYPOGRAPHY END

        padding: 3em 0 2em;
        h5 {
            margin-bottom: 1em;
        }
        p, a {
            font-size: $font-size-small;
        }
    }
    .rd-contact-info {
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: center;
        h4 {
            font-size: $font-size-medium;
            font-weight: normal;
        }
        hr {
            width: 30%;
            opacity: 0.2;
            border-width: 1px;
            margin: 2em auto;
        }
        a {
            color: $light-extra;
            text-decoration: none;
        }
        [class*="icon"] {
            color: $light-extra;
            transition: all 0.2s ease-in;
            font-size: $font-size;
            padding: 0 0.5em;
            &:hover {
                color: $medium;
            }
        }
    }
    .rd-creator {
        font-size: $font-size-xsmall;
        text-align: center;
        a {
            color: $light-extra;
            font-size: 12px;
            text-decoration: underline;
        }
    }
    .rd-footer-navigation {
        display: none;

        @include FLOW-at($desktop) {
            display: block;
        }
        padding-left: 3em;
        a {
            padding-bottom: 0.2em;
            text-transform: capitalize;
        }
    }
}
