// -------------------------------------------------------------------
// :: OPLEIDINGEN
// -------------------------------------------------------------------
.rd-opleiding-block{
     @include FLOW-at($tablet){
            margin-top: 20em;
    }
    @include FLOW-at($desktop){
        margin-top: 10em;
    }

    clear: both;
}
.rd-opleidingen{
    display: none;
	padding: 0;
	margin-top: 2em;
    @include FLOW-at($tablet){
        display: block;
    }
	li{
		display: inline;
		list-style: none;
	}
	a{
		display: block;
		padding: 0.5em 0.8em;
		border: 1px solid $medium;
		text-decoration: none;
		margin-bottom: 0.5em;
		color:  $secondary-color;
		position: relative;
		transition:all 0.2s ease-in;
		
		span{
			@extend .icon-arrow-right;
			position: absolute;
			top: 2px;
			font-size: 2.5em;
			line-height: 0;
			right: 0;
			transition:right 0.2s ease-in;
		}
		&:hover{
			background-color: $light;
			span{
				right: -3px;
			}
		}
		
		&.rd-is-active{
			background-color: $secondary-color;
			border-color: $secondary-color;
			color: $light-extra;
			span{
				@extend .icon-arrow-left;
			}
		}
		&.rd-external{
			color: $primary-color;
			span{
				@extend .icon-full-arrow-right;
			}
			&:hover{
				background-color: $primary-color;
				border-color: $primary-color;
				color: $light-extra;
			}
		}
	}
}