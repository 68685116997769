// -------------------------------------------------------------------
// :: HEADER
// -------------------------------------------------------------------
@import "../_globals";
@import "../base/_layout";
@import "../base/_components_icons";
@import "../utils/_media-queries";
@import "../utils/_functions";
header {
    height: 80vh;
    max-height: 900px;
    position: relative;
    text-align: center;
    margin-bottom: 3em;

    &.smallheader{
        height: 40vh;
    }

    &.bigheader{
        height: 60vh;
    }
}

header .rd-container {
    font-size: rem(16px);
    h1 {

        font-size: rem(45px);
        line-height: 1.025;
        letter-spacing: 2px;
        @include FLOW-at($tablet) {
            font-size: rem($font-size-xxxlarge);
                        font-size: rem($font-size-xxxlarge);

        }
    }
    h2 {
        @include FLOW-at($tablet) {
            font-size: rem($font-size-xxlarge);
        }
        font-size: rem($font-size-xlarge);
        font-weight: bold;
        line-height: 1.025;
        letter-spacing: 1px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
    h3 {
        font-size: rem($font-size-xlarge);
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
    }
    h4 {
        font-size: rem($font-size-large);
        line-height: 1.45;
    }
    h5 {
        font-size: rem($font-size);
        line-height: 1.55;
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    h6 {
        line-height: 1.55;
        font-size: rem($font-size-small);
        font-weight: 200;
        text-transform: uppercase;
        font-family: "Roboto Condensed";
        letter-spacing: 1px;
        sup {
            font-size: 9px;
        }
    }
    position: relative;
    padding: 2em 6em;
    .logo {
        position: absolute;
        top: 2em;
        left: -0.8em;
        width: 6em;
        height: auto;
        z-index: 9;
    }
}

header {
    .rd-slide {
        @include spread();
        max-height: 9999px;
        z-index: 1;
        opacity: 0;
        &#slide-1 {
            z-index: 2;
            opacity: 1;
        }
    }
}

.rd-inner-slide {
    width: 100%;
    @include FLOW-at($desktop) {

        margin: 0 auto;
    }
}

header.rd-with-spotlight {
    margin-bottom: 10em;
    .rd-hero {
        padding-bottom: 4em;
    }
    .rd-slide-navigation {
        bottom: 5em;
    }
    .rd-inner-slide {
        margin-top: -2em;
    }
}

header .rd-secondary-navigation {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
}

.rd-slide-navigation {
    position: absolute;
    width: 100%;
    bottom: 3em;
    left: 0;
    text-align: center;
    z-index: 11;
    a {
        display: inline-block;
        margin: 0 0.2em;
        height: 10px;
        width: 10px;
        border: 2px solid $light-extra;
        border-radius: 100%;
        transition: all 0.5s ease-in;
        &.rd-is-active {
            background-color: $light-extra;
        }
        &:hover {
            border-color: $primary-color;
            &.rd-is-active {
                background-color: $primary-color;
            }
        }
    }
}


