// -------------------------------------------------------------------
// :: LISTS
// -------------------------------------------------------------------
// By default lists are not included in base/_normalize.scss
// So each class extending the default (e.g. inline-list) must
// handle the reset in itself.

//ul,
//ol {
//  padding-left: 0;
//}


// -------------------------------------------------------------------
// :: INLINE LISTS
// -------------------------------------------------------------------
// There are 2 different strategies to implement inline-lists,
// each with their own specific quirks to deal with
//
// 1) Using float: left
// This method forces us to add a clearfix on the parent element
// and has no way of centering list-content without providing a
// fixed width and margin: auto to the parent element
//
// 2) Using display: inline-block
// This basically does the same, but includes the option of
// centering list-content - by adding text-align: center to
// the parent element
//
// However...
// - inline-block is not supported in <IE8
// - this method adds additional margins in un-minified markup
// - list-content always need to be wrapped in something (li)
//
// - http://theamazingweb.net/2013/06/24/fixing-display-inline-block
// - http://css-tricks.com/fighting-the-space-between-inline-block-elements
// - http://alistapart.com/article/taminglists

.inline-list {
  letter-spacing: -0.28em;
  padding-left: 0;
}

.inline-list > * {
  letter-spacing: normal;
  display: inline-block;
}

// -------------------------------------------------------------------
// :: EXTENDED
// -------------------------------------------------------------------

.list-overview{
	margin: 10px 0;
	padding: 0;
	display: table;
	width: 100%;
	border-collapse: collapse;
	li{
		background-color: $light-extra;
		margin: 8px auto;
		list-style: none;
		& > div{
			padding: 15px 15px 13px;
			display: table-cell;
			width: 100%;
			vertical-align: middle;
			&.small{
				text-align: center;
			}
		}
		&:not(.list-header) > div{
			border:1px solid $border-color;
		}
		&.list-header{
			margin-top: -60px;
			[class*="icon"]{
				margin: 0 2px;
			}
		}
	}
	p{
		font-size: 14px;
	}
	span{
		margin: 0px 10px;
		display: block;
		width: 25px;
		height: 25px;
		&.count{
			border: 1px solid $primary-color;
			color: $primary-color;
			border-radius:100%;
			font-size: 13px;
			font-weight: bold;
			line-height: 25px;
			&.is-null{
				color: $border-color;
				border-color: $border-color;
			}
		}
		&[class*="icon"]{
			color: $primary-color;
			text-align: center;
			width: auto;
			height: auto;
			margin: 0;
			&:before{
				font-size: 2.5em;
			}
		}
	}
}

.opp-small{
	p{
		font-size: 16px;
	}
}
.quotes{
	li > div{
		width: 50%;
	}
}
