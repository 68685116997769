// -------------------------------------------------------------------
// :: TOOLTIPS
// -------------------------------------------------------------------

@mixin FLOW-tooltip($_direction: "up", $_color: darken($light, 4%)) {

  color: $font-color-medium;
  background: $_color;

  &:before {
    @include FLOW-css-triangle($_direction, 0.75em, $_color);
    position: absolute;

    @if $_direction == "up" {
      transform: translate(-50%, -100%);
      left: 1.5rem; //50%
      top: 0;
    }

    @if $_direction == "right" {
      transform: translate(100%, -50%);
      right: 0;
      top: 50%;
    }

    @if $_direction == "down" {
      transform: translate(-50%, 100%);
      left: 50%;
      bottom: 0;
    }

    @if $_direction == "left" {
      transform: translate(-100%, -50%);
      left: 0;
      top: 50%;
    }
  }

}


// -------------------------------------------------------------------
// :: GENERAL TOOLTIP CLASS
// -------------------------------------------------------------------

[class*='tooltip'] {
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  position: relative;
  line-height: 1;
  padding: 0.9rem 1rem 0.8rem;
}

[class*='tooltip']:before {
  position: absolute;
}

[class*='tooltip'] a {
  color: inherit;
}


// -------------------------------------------------------------------
// :: TOOLTIP CLASSES
// -------------------------------------------------------------------
// Tooltip up

.tooltip-up{
  @include FLOW-tooltip("up");
}

.tooltip-up.is-error {
  @include FLOW-tooltip("up", $ui-red);
}

.tooltip-up.is-warning {
  @include FLOW-tooltip("up", $ui-orange);
}

.tooltip-up.is-success {
  @include FLOW-tooltip("up", $ui-green);
}

.tooltip-up.is-information {
  @include FLOW-tooltip("up", $ui-blue);
}

// Tooltip right

.tooltip-right{
  @include FLOW-tooltip("right");
}

.tooltip-right.is-error {
  @include FLOW-tooltip("right", $ui-red);
}

.tooltip-right.is-warning {
  @include FLOW-tooltip("right", $ui-orange);
}

.tooltip-right.is-success {
  @include FLOW-tooltip("right", $ui-green);
}

.tooltip-right.is-information {
	@include FLOW-tooltip("right", $ui-blue);
}

// Tooltip down

.tooltip-down {
  @include FLOW-tooltip("down");
}

.tooltip-down.is-error {
  @include FLOW-tooltip("down", $ui-red);
}

.tooltip-down.is-warning {
  @include FLOW-tooltip("down", $ui-orange);
}

.tooltip-down.is-success {
  @include FLOW-tooltip("down", $ui-green);
}

.tooltip-down.is-information {
  @include FLOW-tooltip("down", $ui-blue);
}

// Tooltip left

.tooltip-left {
  @include FLOW-tooltip("left");
}

.tooltip-left.is-error {
  @include FLOW-tooltip("left", $ui-red);
}

.tooltip-left.is-warning {
  @include FLOW-tooltip("left", $ui-orange);
}

.tooltip-left.is-success {
  @include FLOW-tooltip("left", $ui-green);
}

.tooltip-left.is-information {
  @include FLOW-tooltip("left", $ui-blue);
}


// color exceptions

[class*="tooltip"].is-error,
[class*="tooltip"].is-warning,
[class*="tooltip"].is-success,
[class*="tooltip"].is-information{
	color: $light-extra;
}

