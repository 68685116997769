// -------------------------------------------------------------------
// :: IMAGES
// -------------------------------------------------------------------
// Images are set out to be fluid by default
// Inspired by the Inuit.css framework
//
// - https://github.com/csswizardry/inuit.css/blob/master/base/_images.scss
// - http://demosthenes.info/blog/586/CSS-Fluid-Image-Techniques-for-Responsive-Site-Design
// - http://www.stucox.com/blog/dont-squash-me-using-min-width-on-fluid-images

.img-respsonsive{
  width: 100%;
  height: auto;
}

.profile-picture{
  width: 100%;
  height: auto;
  max-width: 200px;
}

.profile-filter{
  width: 100%;
  height: auto;
  min-height: 250px;
}

/*

img {
  outline: 0;
  border: 0;
}

img:not([width]):not([height]):not(.logo) {
  max-width: 100%;
  height: auto;
}

img[width],
img[height] {
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

*/


// -------------------------------------------------------------------
// :: CONTEXTUAL IMAGES
// -------------------------------------------------------------------

/*

figure {
  margin: 0;
  text-align: center;
  display: block;
}

figure img {
  display: block;
}

*/


// -------------------------------------------------------------------
// :: OVERLAYS
// -------------------------------------------------------------------
// Mostly used in headers, heroes and/or mastheads
// Image overlays should have an opacity between 20-60%
// depending on context, for this we use font-color values

//.has-overlay {
//  position: relative;
//  color: $white;
//  background-position: center;
//  background-size: cover;
//  padding: 1em;
//}

//.has-overlay:before {
//  content: '';
//  width: 100%;
//  height: 100%;
//  background-color: $font-color-light;
//  position: absolute;
//  left: 0;
//  top: 0;
//}

//.has-overlay > * {
//  position: relative;
//  z-index: 1;
//}

