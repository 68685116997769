// -------------------------------------------------------------------
// :: HERO
// -------------------------------------------------------------------



.rd-hero{
	height: 50vh;
	max-height: 400px;
	position: relative;
	@include cover();
  

	&:before{
		content: "";
		display: block;
		@include spread();
		//background-color: $dark;
		//opacity: 0.2;
	}
}


.rd-hero{
h1,
h2,
h4,
blockquote {
  font-weight: 100;
}

h3,
h5,
h6,
strong {
  font-weight: 700;
}
	h1,
	h2,
	h3,
	h4,
	p{
		color: $light-extra;	
	}
	
	.rd-button{

		padding-left:2em;
		padding-right: 2em;
		margin-top:1em;
	}
		
	h1{
		margin: 0.1em 0 0.1em;
        text-transform: uppercase;
        letter-spacing: -0.02em;
        font-size: rem(45px);
        line-height: 1.025;
        letter-spacing: 2px;
         @include FLOW-at($tablet) {
            font-size: rem($font-size-xxxlarge);

        }
	}
	
	hr,
	h3{
		margin: 0 50px;
        font-size: rem($font-size-xlarge);
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
	}
	hr{
		border-color: $light-extra;
	}
	
	p{
		font-size: $font-size-large;
		font-weight: 500;
		margin: 1em 0 0;
        line-height: 1.2em;
	}
}


section.rd-hero{
	 margin-top: 18em;
    @include FLOW-at($tablet){
           margin-top: 3em;
    }

    
}