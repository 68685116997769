// -------------------------------------------------------------------
// :: WORDPRESS GENERATED CLASSES
// -------------------------------------------------------------------
@import "../_globals";
@import "../base/_layout";
@import "../base/_components_icons";
@import "../utils/_media-queries";
@import "../utils/_functions";

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size * $line-height)) !default; // ~20px

// -------------------------------------------------------------------
// :: THUMBNAILS
// -------------------------------------------------------------------

// Padding around the thumbnail caption
$thumbnail-caption-padding:   9px !default;

// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment

.alignnone {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: auto;
}
.aligncenter {
    display: block;
    margin: ($line-height-computed / 2) auto;
    height: auto;
}
.alignleft,
.alignright {
    margin-bottom: ($line-height-computed / 2);
    height: auto;
}

@include FLOW-at ($mobile){
    // Only float if not on an extra small device
    .alignleft {
        float: left;
        margin-right: ($line-height-computed / 2);
    }
    .alignright {
        float: right;
        margin-left: ($line-height-computed / 2);
    }
}

// Captions
.wp-caption {
    @extend .thumbnail;
}
.wp-caption-text {
    padding: $thumbnail-caption-padding;
}

// Text meant only for screen readers
.screen-reader-text {
    @extend .sr-only;
    @extend .sr-only-focusable;
}

// padding
.section-top{
    margin-top: 5rem;
}