@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";
@import "../base/_components_icons";
// -------------------------------------------------------------------
// :: NAVIGATION
// -------------------------------------------------------------------
.rd-main-navigation-block {
    top: 0;
    left: 0;
    right: 0;
    font-size: rem(16px);
    position: fixed;
    background-color: $light-extra;
    min-height: 76px;
    display: none;
    z-index: 999999;
    box-shadow: 0 5px 15px rgba(124,123,128,0.15);

    @include FLOW-at($tablet) {
        display: block;
    }

    &::after {
        content: "";
        display: block;
        clear: both;
    }

    h1 {
        @include FLOW-at($tablet) {
            font-size: rem($font-size-xxxlarge);
        }
        font-size: rem(45px);
        line-height: 1.025;
        letter-spacing: 2px;
    }

    h2 {
        @include FLOW-at($tablet) {
            font-size: rem($font-size-xxlarge);
        }
        font-size: rem($font-size-xlarge);
        font-weight: bold;
        line-height: 1.025;
        letter-spacing: 1px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    h3 {
        font-size: rem($font-size-xlarge);
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 1.45;
    }

    h4 {
        font-size: rem($font-size-large);
        line-height: 1.45;
        color: $secondary-color;
    }

    h5 {
        font-size: rem($font-size);
        line-height: 1.55;
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    h6 {
        line-height: 1.55;
        font-size: rem($font-size-small);
        font-weight: 200;
        text-transform: uppercase;
        font-family: "Roboto Condensed";
        letter-spacing: 1px;
        sup {
            font-size: 9px;
        }
    }

    .rd-logo-img {
        @include FLOW-at($desktop) {
            height: 76px;
            width: auto;
        }
    }
}

.rd-logo-vub {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.rd-right-menu-block {
    position: absolute;
    height: 100%;
    right: 0;
    border-left: 1px solid $medium;
}

.rd-right-menu-block,
.rd-logo-vub {
    width: 20%;
    text-align: center;
}

.rd-logo-vub {
    background-image: url(../images/layout/logo-color.svg);
    background-size: auto 70px;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 76px;
    width: 10%;
    @include FLOW-at($desktop) {
        background-image: url(../images/layout/logo.svg);
        width: 20%;
    }
}

.rd-top-navigation {
    float: left;
    padding: 0.16em 0 0;
    a:first-child {
        padding-left: 0;
    }
}

.rd-language-navigation {
    min-height: 38px;
    height: 50%;
    width: 100%;
    float: left;
    border-bottom: 1px solid $medium;
    text-align: center;
    margin: -0.1em 0;
    a {
        color: $dark;
        text-decoration: none;
        font-family: "Roboto Condensed";
        text-transform: uppercase;
        letter-spacing: 1px;
        position: relative;
        display: inline-block;
        padding: 0 0.2em;
        margin-top: 1.1em;
        font-size: $font-size-xsmall;
        line-height: $font-size-xsmall;
        @include FLOW-at($desktop) {
            padding: 0 1em;
        }
        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 100%;
            position: absolute;
            top: 1px;
            right: 0;
            background-color: $dark;
            opacity: 0.5;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        &:hover {
            color: $font-color-medium;
        }
    }
    a:last-child {
        padding-right: 0;
    }
}

.rd-info-block {
    float: left;
    min-height: 42px;
    height: 50%;
    width: 70%;
    border-right: 1px solid $medium;
    padding: 0.6em;
    color: $dark;
    text-decoration: none;
    font-family: "Roboto Condensed";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: $font-size-xsmall;
    line-height: $font-size-xsmall;
    .rd-sub-menu {
        text-align: left;
        z-index: 999;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        background-color: $light-extra;
        border-radius: 0 0 $border-radius $border-radius;
        box-sizing: border-box;
        padding: 0 6em 0 4em;
        border-top: 1px solid $medium;
        display: none;
        ul {
            padding: 0px;
        }
        li {
            display: block;
        }
        a {
            color: $font-color;
            &:hover {
                color: $font-color-medium;
            }
        }
    }
    &:after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 1px;
        right: 0;
        background-color: $dark;
        opacity: 0.5;
    }
    &:last-child {
        &:after {
            display: none;
        }
    }
    &:hover {
        color: $font-color-light;
        background-color: $dark;
        .rd-sub-menu {
            display: block;
        }
    }
    span {
        font-size: $font-size-xlarge;
    }
}

.rd-search-block {
    width: 30%;
    min-height: 42px;
    height: 50%;
    padding: 0.4em;
    float: left;
    .rd-sub-search {
        position: relative;
        z-index: 999;
        background-color: $light-extra;
        border-radius: 0 0 $border-radius $border-radius;
        width: 500px;
        float: right;
        box-sizing: border-box;
        display: none;
        margin-right: -6px;
        margin-top: 8px;
        border-top: 1px solid $medium;
        input {
            border: none;
            background-color: $light;
            color: $dark;
            width: 100%;
        }
    }
    &:hover {
        .rd-sub-search {
            display: block;
        }
        background-color: $dark;
        color: $light;
    }
}

.rd-main-navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 10%;
    position: absolute;
    height: 100%;

    @include FLOW-at($desktop) {
        left: 20%;
    }
}

.rd-main-navigation,
.rd-mobile-main-navigation {
    text-align: center;
    z-index: 999;
    width: 70%;
    margin: 0 auto;
    font-size: 0.9em;

    @include FLOW-at($desktop) {
        width: 60%;
        font-size: 1em;
    }
    .rd-menu-item {
        display: inline-block;
        height: 100%;
        .rd-sub-menu {
            position: absolute;
            background-color: $light-extra;
            border-radius: 0 0 $border-radius $border-radius;
            width: 100%;
            left: 0;
            box-sizing: border-box;
            padding: 0 3em;
            display: none;
            border-top: 1px solid $medium;
            .rd-container {
                width: 100%;
                text-align: left;
                padding: 0;
                h5 {
                    padding: 3em 0 1em;
                    color: $secondary-color;
                }
                a {
                    display: block;
                    text-decoration: none;
                    color: $dark;
                    padding: 0.5em 0;
                    line-height: 1.5;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
            .rd-social {
                padding: 1.5em 0 1.8em;
                border-top: 1px solid $medium;
                a {
                    text-decoration: none;
                    color: $dark;
                    font-size: $font-size;
                    line-height: 0;
                    padding: 0 0.5em;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
        &:hover {
            .rd-sub-menu {
                display: block;
            }
            & > a {
                background-color: $light-extra;
                color: $dark;
            }
        }
        & > a {
            min-height: 75px;
            height: 100%;
            line-height: 100%;
            padding: 2em 0.3em;
            font-family: "Roboto Condensed";
            text-transform: uppercase;
            color: $dark;
            text-decoration: none;
            font-weight: 700;
            letter-spacing: 1px;
            display: inline-block;
            position: relative;
            border-radius: $border-radius $border-radius 0 0;
            @include FLOW-at($desktop-xl) {
                padding: 1.5em 0.8em;
            }
            &:hover {
                color: $orange;
            }
        }
    }
}

.rd-secondary-navigation {
    .rd-navigation-holder {
        background-color: $light-extra;
        border-radius: $border-radius;
        position: relative;
        z-index: 2;
        bottom: -3em;
        display: inline-block;
        padding: 0 5em 1em;
        width: auto;
        margin: 0 auto;
        text-align: center;
        .rd-navigation-inner {
            padding: 5px 0 0;
            text-align: center;
            border-bottom: 1px solid $medium;
            display: inline-block;
            width: auto;
            font-size: 0;
            margin: 0 auto;
            a {
                display: inline-block;
                padding: 0.8em;
                text-decoration: none;
                margin: 0 1.5em -1px;
                position: relative;
                overflow: hidden;
                font-size: $font-size;
                color: $font-color;
                &:first-child {
                    @include FLOW-at($desktop) {
                        margin-left: 0;
                    }
                }
                &:last-child {
                    @include FLOW-at($desktop) {
                        margin-right: 0;
                    }
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    bottom: -3px;
                    background-color: $primary-color;
                    transition: all 0.2s ease-in;
                }
                &.rd-is-active,
                &:hover {
                    color: $secondary-color;
                    &:after {
                        bottom: 0px;
                    }
                }
            }
        }
    }
}

.rd-footer-navigation {
    a {
        display: block;
        text-decoration: none;
        color: $light-extra;
    }
}

.rd-sub-navigation {
    width: 100%;
    margin: 0 auto;
    padding: 2em 0;
    h4 {
        font-size: $font-size;
        margin-bottom: 1em;
        color: $secondary-color;
        font-family: $font-family-text;
        font-weight: 700;
    }
    a {
        display: block;
        text-decoration: none;
        font-size: $font-size;
        color: $font-color;
    }
}

nav.rd-sidebar-navigation {
    a {
        display: block;
        color: $font-color;
        padding: 0.2em 0;
        &.rd-is-active {
            color: $primary-color;
            font-weight: bold;
            @extend .icon-arrow-right;
            &:before {
                font-size: 2em;
                line-height: 0;
                margin-left: -0.5em;
            }
        }
    }
}

nav.rd-tab-navigation {
    border-bottom: 1px solid $secondary-color;
    padding-bottom: 0;
    a {
        padding: 1em 2em;
        color: $font-color;
        font-weight: 500;
        display: inline-block;
        margin-bottom: -1px;
        &.rd-is-active {
            border: 1px solid $secondary-color;
            color: $secondary-color;
            border-bottom-color: $light-extra;
        }
    }
}

.rd-mobile-navigation-block {
    background: $light-extra;
    width: 100%;
    position: relative;
    box-shadow: 0 5px 15px rgba(124,123,128,0.15);

    @include FLOW-at($tablet) {
        display: none;
    }
    .rd-logo-vub {
        width: 70px;
        height: 60px;
        background-size: auto 60px;
    }
    .rd-mobile-main-navigation {
        text-align: center;
        z-index: 999;
        width: 70%;
        margin: 0 auto;
        font-size: 0.9em;
        @include FLOW-at($desktop) {
            font-size: 1em;
        }

        display: none;
        position: absolute;
        width: 100%;
        background-color: $light-extra;
        left: 0;
        top: 60px;
        .rd-menu-item {
            clear: both;
            width: 100%;
        }
    }
    .rd-menu-button {
        height: 60px;
        right: 0;
        position: relative;
        padding-left: 90%;
        width: 100%;

        color: $secondary-color;
        &:before {
            text-align: center;
            font-size: 2.5em;
        }
        line-height: 2.5em;

        &:hover {
            .rd-mobile-main-navigation {
                display: block;
            }
        }
    }
}

// SECOND NAVIGATION

.rd-subnav-wrapper{
    position:absolute;
    bottom: 0;
    z-index:5;
    left: 0;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;

    .rd-subnav{
        position: absolute;
        top:0;
        background-color: #FFFFFF;
        display:flex;
        flex-direction: column;
        &:hover{
            transition: all 0.3s ease-out;
            box-shadow: 0px 4px 8px rgba(0,0,0,0.3);
            .rd-subnav-list{
                display:flex;
                flex-direction: column;
            }
        }
    }

    .rd-subnav-button{
        background-color:#FFFFFF;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        font-weight: bold;
        color: $blue;
        padding: 10px;
        z-index:5;
        margin-top: -20px;
        .icon-arrow-down{
            &:before{
                font-size: 1.7em;
            }

        }
    }

    .rd-subnav-list{
        display:none;
        position:relative;
        margin: 0;
        padding: 0;
        li{
            background-color: #FFFFFF;
            list-style-type:none;
            margin: 0;
        }
    }

    .rd-subnav-item-link{
        padding: 5px 15px;
        background-color: #FFFFFF;
        width:100%;
        display:block;
        &:hover{
            background-color: darken(#FFFFFF, 5%);
        }
    }
}
