@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// -------------------------------------------------------------------
// :: COMMENTS
// -------------------------------------------------------------------

#comments{

    margin-top: 3em;

    ul.comment-list{
        list-style: none;
        padding: 0;

        li.comment{
            display: block;
            width: 100%;
            padding: 10px;

            .comment-author.vcard{

                img{
                    display: block;
                    float: left;
                    margin-right: 10px;
                }

                .vcard-info{
                    display: block;
                    width: 100%;

                    .name{
                        font-weight: bold;
                        text-transform: none;
                    }

                    .datetime{
                        color: #AAAAAA;
                        font-size: .8em;
                    }
                }
            }

            .thecomment{
                padding-top: .5em;
                padding-left: 70px;
                display: block;
                width: 100%;
            }

            .reply{
                display: block;
                padding-left: 70px;
                margin-top: .5em;
            }
        }

        li.comment.thread-odd{
            background-color: $light;
        }
    }

    #respond{
        margin-top: 3em;
    }

    #commentform{

        p.form-submit{

            input{
                border: none;
                background-color: $orange;
                color: $light-extra;
                font-weight: bold;
                transition: all 100ms ease-in-out;

                &:hover {
                    color: $light-extra;
                    background-color: darken($orange, 5%);
                    cursor: pointer;
                }
            }
        }
    }
}