// -------------------------------------------------------------------
// :: CONVERT PX TO REM
// -------------------------------------------------------------------
// Important: rem-units are not supported on <IE9
// and we don't provide a fallback
//
// - http://caniuse.com/#search=rem

@function rem($_value) {
	@return #{$_value / 16px}rem;
}


// -------------------------------------------------------------------
// :: COVERT PX TO EM
// -------------------------------------------------------------------
// Em values are always relative to the parent element so
// you might need to provide context-value (eg. font-size
// of the parent element) to get the correct value
//
// - font-size: em(16px) => 1em
// - font-size: em(16px, 24px) => 0.666em

@function em($_value, $_context: 16px) {
	@return #{$_value / $_context}em;
}


// -------------------------------------------------------------------
// :: STRIP UNIT
// -------------------------------------------------------------------
// Strips a value from its unit

@function strip-unit($_value) {
	@return $_value / ($_value * 0 + 1);
}





// -------------------------------------------------------------------
// :: MIXINS
// -------------------------------------------------------------------
// Custom mixins to make standard behaviour & css more modular
//
//

// :: CLEARFLOAT
// clear float that can be applied to an element where all the childeren are floated. 

@mixin clearfloat() {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}
.clearfloat{
	@include clearfloat();
}

.rd-container,
.rd-cols{
	clear: both;
	position: relative;
	@include clearfloat();
}


@mixin spread(){
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@mixin cover(){
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

// :: FLEXIBLE BOX
// Forces a box to wrap around its content without exceeding the max-width 

@mixin flexible-box() {
	display: inline-block;
	max-width: 100%;
}
.flexible-box{
	@include flexible-box();
}

// :: CENTERED
// Use this to center something

@mixin centered() {
	text-align: center;
}
