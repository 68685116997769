@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// -------------------------------------------------------------------
// :: ARTICLE
// -------------------------------------------------------------------

section{

    article.rd-container{
        display: block;
        padding: 0 0 3rem 0;
    }
}

body.search.search-results{

    main.main{

        article.page{

            display: block;
            margin-bottom: 2em;
        }
    }
}