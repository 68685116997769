// -------------------------------------------------------------------
// :: ARTICLE
// -------------------------------------------------------------------

.rd-article{
	position: relative;
	text-decoration: none;
	.rd-article-thumb{
		font-size: 0;
		position: relative;
		overflow: hidden;
		max-height: 245px;
		&:after{
			content: "";
			display: block;
			height: 6px;
			width: 100%;
			position: absolute;
			left: 0;
			bottom: -10px;
			background-color: $primary-color;
			opacity: 0;
			transition:all 0.2s ease-in;
		}
		img{
			border: 0;
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.rd-article-header{
		padding: 2em 0;
		margin-bottom: 2em;
		position: relative;
		&:after{
			content: "";
			display: block;
			height: 1px;
			width: 100px;
			background-color: $secondary-color;
			position: absolute;
			left: 0;
			bottom: 0;
		}
		h5{
			color: $secondary-color;
			margin-bottom: 0.5em;
		}
	}

	&:hover .rd-article-thumb:after{
		opacity: 1;
		bottom: 0;
	}
	h4,p{
		color: $font-color;
	}
}

.rd-events .rd-article{
	.rd-article-thumb{
		position: absolute;
		top: 5px;
		left: 0;
		width: 30%;
	}
	.rd-article-header{
		padding: 0 0 1.5em;
		margin-bottom: 1em;
		h4{
			font-size: $font-size-medium;
		}
		h5{
			font-size: $font-size-small;
		}
	}
	.rd-article-header,
	p{
		margin-left: 35%;
		width: 65%;
	}

	.rd-article-header, .rd-button-secondary{
		margin-left: 35%;
	}
}

.events{
	text-decoration: none;

	.rd-article-header{
		padding: 0 0 1.5em;
		margin-bottom: 1em;
		position: relative;

		&:after{
			content: "";
			display: block;
			height: 1px;
			width: 100px;
			background-color: $secondary-color;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		h4{
			font-size: $font-size-medium;
		}
		h5{
			font-size: $font-size-small;
			color: $secondary-color;
			margin-bottom: 0.5em;
		}
	}

	h4,p{
		color: $font-color;
	}
}
