// Secondary Menu

#secondary-mobile {
  position: absolute;
  left: 0;
  right: 0;
  width: 300px;
  bottom: auto;
  margin-top: -2em;
  padding: 0;

  ul.rd-navigation-inner {

    padding: 0;
    border-bottom: none;

    li.page_item.current_page_item, li.page_item {

      display: none;
      float: none;
      margin: 0;

      a {
        padding: 0.5em 0 0.5em 0.5em;
      }
    }

    li.page_item.current_page_item.subactive {
      display: block;
    }

    li.page_item.displaysub {
      display: block;
    }

    li.page_item.current_page_item {

      a {
        padding-top: 0;
        color: $secondary-color;
        &:after {
          height: 0;
          background-color: $light;
          bottom: 0;
        }
      }
      .icon-arrow-down {
        font-size: 1.7em;
      }
    }
  }
}

#secondary-normal {
  .rd-navigation-inner {
    padding: 5px 0 0;
    text-align: center;
    border-bottom: 1px solid $medium;
    display: inline-block;
    width: auto;
    font-size: 0;
    margin: 0 auto;
    a {
      display: inline-block;
      padding: 0.8em;
      text-decoration: none;
      margin: 0 1.5em -1px;
      position: relative;
      overflow: hidden;
      font-size: $font-size;
      color: $font-color;
      &:first-child {
        @include FLOW-at($desktop) {
          margin-left: 0;
        }
      }
      &:last-child {
        @include FLOW-at($desktop) {
          margin-right: 0;
        }
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        bottom: -3px;
        background-color: $primary-color;
        transition: all 0.2s ease-in;
      }
      &.rd-is-active,
      &:hover {
        color: $secondary-color;
        &:after {
          bottom: 0px;
        }
      }
    }

    li.page_item.current_page_item, li.page_item {

      float: left;
      margin: 0 20px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      a {
        padding: .8em;
      }
    }

    li.page_item.current_page_item {

      a {
        color: $secondary-color;

        &:after {
          height: 2px;
          background-color: $orange;
        }
      }
    }
  }
}

.rd-mobile-main-navigation{

  .menu-item-has-children{

    .rd-menu-link{

      span.icon-arrow-down{
        display: inline-block !important;
        font-size: 1.7em;
      }
    }
    .dropper--items{
      display: none;
    }
    .dropper--items.navshow{
      display: block;
    }
  }
}