.rd-logos{
	.rd-logo {
		height: 150px;
		padding: 0;
		white-space: nowrap;
		text-align: center;
		margin: 1rem;
		display: block !important;
		position: relative;
		width: 48%;
	}

	img {
		transition: all 300ms ease-in;
		width: 75%;
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
		margin-left: auto;
		margin-right: auto;
	}

	.image-helper {
		display: block;
		vertical-align: middle;
	}
}