@import "../_globals";
@import "../utils/_media-queries";
@import "../utils/_functions";

// List of Events

#upcoming-events{

  margin-top: 3em;

  .rd-section-header{

    h2{
      display: block;
      width: 100%;
      margin-bottom: .5em;

      @include FLOW-at($tablet){
        display: inline-block;
        width: auto;
        margin-bottom: 0;
      }
    }

    a.rd-section-more{
      display: block;
      float: left;

      @include FLOW-at($tablet){
        float: right;
      }
    }
  }
}

.rd-events{

  .rd-article-thumb{
    max-height: 100%;
  }
}

// Single Event

#detail-event{
  .rd-article{
    &:hover .rd-article-thumb:after{
      opacity: 0 !important;
    }

    .rd-article-thumb{
      //max-height: none;
    }

    &:after{
      display: none;
    }
  }
}