// -------------------------------------------------------------------
// :: FORMS
// -------------------------------------------------------------------
// Every form should have an action- and method-attribute
// The submit button should be added as input[type=submit]
//
// Every input should have a label with corresponding for-, id- and
// name-attributes. Labels are always placed before inputs, nesting
// is not allowed (except for checkboxes and radio's)

@include FLOW-normalize-forms();

// -------------------------------------------------------------------
// :: FIELDSET
// -------------------------------------------------------------------

fieldset{
	border:0;
	border-bottom: 1px solid $border-color;
	padding: 2em;
	&:last-child{
		border-bottom: 0;
	}
}

// -------------------------------------------------------------------
// :: BASE
// -------------------------------------------------------------------

select,
textarea,
input:not([class*='button']),
.input__upload,
.editable-area input:focus {
  font-size: rem($font-size-small);
  padding: 0.9em 0.8em 0.8em;
  background-color: $light;
  border: 1px solid $medium;
  border-radius: $border-radius;
  width: 100%;

  transition: border 100ms ease-in-out;

  @include FLOW-input-placeholder() {
    color: $font-color-medium;
  }
}
select,
textarea,
.input__upload{
	width: 100%;
}

select:focus,
textarea:focus,
input:not([class*='button']):not([type="range"]):focus {
  background-color: $light-extra;
}

input[disabled],
select[disabled],
textarea[disabled],
[class*='input__'][disabled] [class*='icon'] {
  background-color: $border-color;
}

// Adjust padding-left
// on search inputs

input[type="search"] {
	padding-left: 0.35em;
}

// Adjust padding-right
// on selects to accomodate for arrow

select{
	padding-right: 2rem;
}
// Add pointer cursor for selects
// Provide styling when no
// value has been selected

select.has-no-value {
  color: $font-color-medium;
  font-style: italic;
  font-weight: 100;
}

select[disabled].has-no-value {
  color: $font-color-medium;
}

// Resize options for textarea
// vertical/horizontal/both/none

textarea {
  resize: vertical;
}


// -------------------------------------------------------------------
// :: GENERAL CLASSES
// -------------------------------------------------------------------

.input {
  margin-bottom: 1.5em;
  @include clearfloat();
}

.input label:not([class*='input__']) {
  font-weight: 100;
  font-size: rem($font-size);
  margin-bottom: 0.25rem;
  display: block;
 }
 .input.label-inline {
	label{
		margin-right: 0.5em;
	}
	label,
	& > div{
		display: inline-block;
	} 
	select,
	textarea,
	input:not([class*='button']),
	.input__upload,
	.editable-area input:focus{
		width: auto;
	}
 }

.input small:not([class*='tooltip']) {
  padding: 0.85rem 0.25rem;
}

.input a {
  position: relative;
  z-index: 2;
}


// -------------------------------------------------------------------
// :: DECORATED INPUTS
// -------------------------------------------------------------------
// Input fields are wrapped to ease placement of
// additional (optional) icons after the input field

.input div {
	position: relative;
}

.input select + [class*='icon'] {
  color: $font-color-medium;
  margin: 0.35rem 0.9rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 3px;
}

.input select + [class*='icon']:before {
  font-size: 1.65em;
  line-height: 0;
}

.input select.has-no-value + [class*='icon'] {
  color: $font-color-medium;
}

// Tooltip messages
// in feedback states

.input [class*='tooltip'] {
  margin-top: 0.35rem;
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;

  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

.input [class*='tooltip'] [class*='icon'] {
  position: absolute;
  left: 0.8em;
  top: 0.8em;

  &:before {
    font-size: 1.45em;
  }
}

// Input with measuring unit

.input .unit{
	position: absolute;
	top: 1px;
	right: 1px;
	font-weight: bold;
	font-size: 1em;
	background-color: $light-extra;
	padding: 0.35em 1.1rem 0.35em 0rem;
	line-height: 1.8em;
}


// -------------------------------------------------------------------
// :: UPLOAD FIELD
// -------------------------------------------------------------------

.input__upload {
  margin: 0 !important;
  padding: 0;
}

.input__upload input[type="file"] {
  left: 0;
}

.input__upload input[type="text"] {
  opacity: 1 !important;
  background: transparent;
  position: relative;
  box-shadow: none;
  border: none;
  padding-bottom: 0.6rem;
  padding-top: 0.9rem;
}

.input__upload input[type="submit"] {
  opacity: 1 !important;
  position: absolute;
  right: 0 !important;
  top: -1px;
}


// -------------------------------------------------------------------
// :: RANGE SLIDERS
// -------------------------------------------------------------------
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

input[type="range"] {
  height: rem(24px);
  padding: 0;
  margin: 0;

  border: 1px solid $border-color;
  border-radius: rem(12px);
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;

  // Style input types on IOS
  // with webkit-appearance

  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=range]::-moz-focus-outer {
  border: 0;
}

// Hide the slider on Windows

input[type="range"]::-ms-track {
  width: 100%;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: transparent;
}

// Styling the knob

input[type="range"]::-webkit-slider-thumb {
  width: rem(26px);
  height: rem(26px);
  border-radius: 50%;
  border: 3px solid $light-extra;
  background: $primary-color;
  box-shadow: 0 0 1px $font-color-medium;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  width: rem(26px);
  height: rem(26px);
  border-radius: 50%;
  border: 3px solid $light-extra;
  background: $primary-color;
  box-shadow: 0 0 1px $font-color-medium;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="range"]::-ms-thumb {
  width: rem(26px);
  height: rem(26px);
  border-radius: 50%;
  border: 3px solid $light-extra;
  background: $primary-color;
  box-shadow: 0 0 1px $font-color-medium;
  -webkit-appearance: none;
  -moz-appearance: none;
}

// Background track
// of the slider

input[type="range"] + .range__background,
input[type="range"] + .range__background + .range__background {
  width: 97%;
  height: rem(8px);
  position: absolute;
  top: rem(8px);
  left: 1.5%;

  background: rgba($dark, 0.08);
  border-radius: rem(4px);
}

input[type="range"] + .range__background + .range__background {
  background: $primary-color;
  width: 0;
}

// Tooltip

.range__background [class*='tooltip'] {
  transform: translate(50%, 50%);
  padding-left: 1rem;
  left: auto;
  right: 0;
  z-index: -1;

  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.range__background [class*='tooltip']:before {
  left: 50% !important;
}


// -------------------------------------------------------------------
// :: IE9 HACK FOR SELECT (DROPDOWN MENU)
// -------------------------------------------------------------------
/*
.ie9 select,
.ie9 select + [class*='icon'] {
  background: #FAF9F9;
}

.ie9 option {
  background-color: transparent !important;
}

.ie9 header .language-selection select {
  padding-right: 0.6em;
}

.ie9 header .language-selection select + [class*='icon'] {
  background: white;
}
*/

// -------------------------------------------------------------------
// :: FEEDBACK STATES
// -------------------------------------------------------------------
// There are 4 possible feedback states: error, success, warning and
// information. These should be styled based on the UI-kit colors

/*
.input.is-error select,
.input.is-error textarea,
.input.is-error input:not([class*='button']) {
  background: $ui-red;

  &:focus {
    border-color: darken($ui-red, 25%);
  }
}
.input.is-error .unit{
	background: $ui-red;
}

.input.is-warning select,
.input.is-warning textarea,
.input.is-warning input:not([class*='button']) {
  background: $ui-orange;

  &:focus {
    border-color: darken($ui-orange, 35%);
  }
}
.input.is-warning .unit{
	background: $ui-orange;
}

.input.is-success select,
.input.is-success textarea,
.input.is-success input:not([class*='button']) {
  background: $ui-green;

  &:focus {
    border-color: darken($ui-green, 35%);
  }
}
.input.is-success .unit{
	background: $ui-green;
}
*/

.input.is-error {
	label{
		color: $primary-color;
		position: relative;
		&:before{
			content: "!";
			display: block;
			background-color: $primary-color;
			text-align: center;
			font-weight: bold;
			line-height: 15px;
			font-size: 12px;
			width: 20px;
			height: 20px;
			border-radius: 100%;
			color: $light-extra;
			position: absolute;
			left: -30px;
			top: 2px;
			line-height: 22px;
			
		}
	}
	[class*='tooltip']{
		background-color: $primary-color;
		color: $light-extra;
		&:before{
			border-bottom-color: $primary-color;
		}
		
	}
}
.input.is-warning{
	[class*='tooltip']{
		background-color: $secondary-color;
		color: $light-extra;
		&:before{
			border-bottom-color: $secondary-color;
		}
		
	}
}

// Show errors
// on focus

.input[class*='is-'] input:focus + [class*='tooltip'] {
  opacity: 1;
}




// -------------------------------------------------------------------
// :: GENERAL RADIO & CHECKBOX CLASSES
// -------------------------------------------------------------------

[class*='input__'] {
  margin: 0.5em 0;
  line-height: rem(20px);
  vertical-align: middle;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

// Style inputs
// and icons

[class*='input__'] input {
  cursor: pointer;
  opacity: 0 !important;
  position: absolute;
  z-index: 2;
  top: -3px;
}

[class*='input__'] input:not(.button) {
  left: 0;
}

[class*='input__'] [class*='icon'] {
  width: rem(20px);
  height: rem(20px);
  margin: 0 1em 0 0;
  transition: all 100ms ease-in-out;
  border: 1px solid $border-color;
  background: $light-extra;
  position: relative;
  top: rem(-2px);
  float: left;
}

[class*='input__'] input:checked ~ [class*='icon'] {
  border-color: $primary-color;
}

[class*='input__'] [class*='icon']:before {
  transition: all 100ms ease-in-out;
  opacity: 0;
}

[class*='input__'] input:checked ~ [class*='icon']:before {
  opacity: 1;
}


// -------------------------------------------------------------------
// :: RADIO CLASSES
// -------------------------------------------------------------------

[class*='input__toggle'] .icon,
[class*='input__radio'] .icon {
  border-radius: 50%;
}
[class*='input__toggle'] .icon:before,
[class*='input__radio'] .icon:before {
  content: '';
  width: rem(6px);
  height: rem(6px);
  margin: rem(6px);
  border-radius: inherit;
  background: $primary-color;
  display: block;
}

// -------------------------------------------------------------------
// :: CHECKBOX CLASSES
// -------------------------------------------------------------------

[class*='input__checkbox'] [class*='icon'] {
  color: $primary-color;
}

[class*='input__checkbox'] [class*='icon']:before {
  position: relative;
  //left: rem(1.5px);
  //top: rem(-1px);
}


// -------------------------------------------------------------------
// :: TOGGLE
// -------------------------------------------------------------------

.input__toggle .toggle {
  width: rem(48px);
  height: rem(24px);
  border-radius: rem(12px);
  top: rem(-3px);
  background-color: transparent;
}

input:checked + .input__toggle .toggle {
  border-color: $border-color;
}

// Circle button

.input__toggle .toggle:before {
  width: rem(12px);
  height: rem(12px);
  margin: rem(5px);
  background: $border-color;
  opacity: 1;
  z-index: 3;
  position: relative;
}

input:checked + .toggle:before {
  transform: translateX(rem(23px));
  background: $primary-color;
}

// Icon decorators

.toggle + [class*='icon-'],
.toggle + [class*='icon-'] + [class*='icon-'] {
  width: auto;
  border: none;
  color: $font-color-medium;
  background: transparent;
  position: absolute;
  top: rem(-1px);
}

.toggle + [class*='icon-'] {
  left: rem(8px);
}

.toggle + [class*='icon-'] + [class*='icon-'] {
  left: rem(26px);
}

// States

.toggle + [class*='icon-']:before { opacity: 0; }
.toggle + [class*='icon-'] + [class*='icon-']:before { opacity: 1; }

input:checked + .toggle + [class*='icon-']:before { opacity: 1; }
input:checked + .toggle + [class*='icon-'] + [class*='icon-']:before { opacity: 0; }





// -------------------------------------------------------------------
// :: DATEPICKER
// -------------------------------------------------------------------
// Using the Pikaday plugin - see /sass/vendor/_pikaday
// https://github.com/dbushell/Pikaday

.datepicker {
  position: relative;
}

.datepicker input {
  background: transparent;
  position: relative;
  z-index: 1;
}

.datepicker .button:not(.button-group) {
  width: rem(54px);
  height: rem(43px);
  line-height: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 0.7em 0.8rem;
}

.datepicker .button span {
  position: relative;
  line-height: 0;
  top: 0.7rem;
}


