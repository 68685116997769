// -------------------------------------------------------------------
// :: GLOBAL LAYOUT
// -------------------------------------------------------------------

body{

}

.rd-container {
	width: 90%;
	max-width: 1260px;
	position: relative;
	margin: auto;

	letter-spacing: -0.28em;
	padding-left: 0;
	& > *{
	  letter-spacing: normal;
	}
}

section .rd-container {
	padding: 3rem 0;
}

section .rd-container-top {
	padding: 2rem 0 0;
}


// -------------------------------------------------------------------
// :: GRID SYSTEM
// -------------------------------------------------------------------
// The grid system should be wrapped inside a div
// with classes: .rd-container and .inline-list


.rd-grid{
	height: auto;
	width: auto;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	& > [class*="col-"]{
		height: 100%;
	}
}
[class*="rd-col-"],
[class*="rd-row-"]{
	position: relative;
}

[class*="rd-col-"]{
	display:inline-block; width: 100%;  vertical-align: top;
	//height: 100%;
	float: left;

	//box-shadow: 0 0 0 1px $font-color-medium;
}
img[class*="rd-col-"]{
	float: left;

}


$col-1-2 : 49.99%;
$col-1-3 : 33.3334%;
$col-2-3 : 66.6668%;
$col-1-4 : 24.99%;
$col-3-4 : 74.99%;


//.col-1-2 { width: $col-1-2;}
//.col-1-3 { width: $col-1-3; }
//.col-2-3 { width: $col-2-3; }
//.col-1-4 { @include FLOW-at($tablet){width: 50%;} @include FLOW-at($desktop){width: 25%;}}
//.col-3-4 { width: $col-3-4; }



.rd-col-1-2 { @include FLOW-at($tablet){width: 50%; } }
.rd-col-1-3 { @include FLOW-at($tablet){width: 33.3334%; } }
.rd-col-2-3 { @include FLOW-at($tablet){width: 66.6668%; } }
.rd-col-1-4 { @include FLOW-at($tablet){width: 50%; } @include FLOW-at($desktop){width: 25%; }}
.rd-col-3-4 { @include FLOW-at($tablet){width: 50%; } @include FLOW-at($desktop){width: 75%; }}



// Extended grid system
// for use in page layouts


.rd-has-default-margins {
  width: 94%;
  @include clearfloat();
  max-width: calc(1260px + 4%);
  padding-left: 2% !important;
}

.rd-has-default-margins [class*="col-"] {
  margin:0  2% 2% 0;
}

.rd-container .rd-has-default-margins {
  width: 102.5%;
  max-width: 102.5%;
  padding-left: 0% !important;
}

.rd-no-margins{
	margin: 0;
}


.rd-has-default-margins .rd-col-1-1 { width: 97.5% !important;}
.rd-has-default-margins .rd-col-1-2 { @include FLOW-at($tablet){ width: 47.75% !important;} }
.rd-has-default-margins .rd-col-1-3 { @include FLOW-at($tablet){width: 31.166% !important;} }
.rd-has-default-margins .rd-col-2-3 { width: 64.332% !important; }
.rd-has-default-margins .rd-col-1-4 {@include FLOW-at($tablet){ width: 47.75% !important;}  @include FLOW-at($desktop){width: 22.875% !important;} }
.rd-has-default-margins .rd-col-3-4 { @include FLOW-at($tablet){width: 72.625% !important;} }


/*
.has-default-margins .col-1-2 { @include FLOW-at($tablet){width: 45.9%;} }   // 46%
.has-default-margins .col-1-3 { @include FLOW-at($tablet){width: 29.265%;} } // 29.3334%
.has-default-margins .col-2-3 { @include FLOW-at($tablet){width: 62.5%;} }   // 62.6668%
.has-default-margins .col-1-4 { @include FLOW-at($tablet){width: 45.9%;} @include FLOW-at($desktop){width: 20.95%;} }  // 21%
.has-default-margins .col-3-4 { @include FLOW-at($tablet){width: 45.9%;} @include FLOW-at($desktop){width: 70.85%;} }  // 71%
*/


.rd-row-1-1{ height: 100%};
.rd-row-1-2{ height: 50%};
.rd-row-1-3{ height: 33.33%};
.rd-row-2-3{ height: 66.66%};
.rd-row-1-4{ height: 25%};
.rd-row-3-4{ height: 75%};

// -------------------------------------------------------------------
// :: UTILITY HELPERS
// -------------------------------------------------------------------
// To provide screen readers an optimal experience
// add hidden DOM-elements with additional info
//
// - http://css-tricks.com/places-its-tempting-to-use-display-none-but-dont

.rd-is-hidden {
  width: 1px !important;
  height: 1px !important;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
}
.rd-scrollable{
	overflow-y: scroll;
}
.rd-right{
	float: right;
}

// Utility classes for
// responsive behaviour

.rd-is-hidden-on-mobile {
  display: none;
  @include FLOW-at($tablet) {
    display: inline-block;
  }
}

.rd-is-hidden-on-tablet-and-up {
  @include FLOW-at($tablet) {
    display: none;
  }
}

.rd-is-hidden-on-desktop {
  @include FLOW-at($desktop) {
    display: none !important;
  }
}


.rd-v-center-outer {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	.rd-v-center{
		display: table;
		height: 100%;
		width: 100%;
		.rd-v-center-inner{
			display: table-cell;
			vertical-align: middle;
			width: 100%;
			text-align: center;
		}
	}
}

.grid{
	min-height: 400px;

	.col-1-4{
		position: relative;
		width: 48%;

		@include FLOW-at($tablet){
			width: 23%;
		}

		@include FLOW-at($desktop){
			width: 18%;
		}
	}
}

.acf-map {
	width: 100%;
	height: 250px;
	border: #ccc solid 1px;
	margin: 20px 0;

	@include FLOW-at($tablet){
		height: 425px;
	}
}

/* fixes potential theme css conflict */
.acf-map img {
	max-width: inherit !important;
}
