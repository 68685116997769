// -------------------------------------------------------------------
// :: TYPOGRAPHY
// -------------------------------------------------------------------
// - https://github.com/railsware/applepie
// - http://www.google.com/design/spec/style/typography.html
// - http://typecast.com/blog/a-more-modern-scale-for-web-typography
// - http://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights
// - http://modularscale.com


@include FLOW-get-font("Roboto", "../fonts/Roboto-Light-Italic", 100, italic);
@include FLOW-get-font("Roboto", "../fonts/Roboto-Light", 100);
@include FLOW-get-font("Roboto", "../fonts/Roboto-Regular", 300);
@include FLOW-get-font("Roboto", "../fonts/Roboto-Italic", 300, italic);
@include FLOW-get-font("Roboto", "../fonts/Roboto-Medium", 500);
@include FLOW-get-font("Roboto", "../fonts/Roboto-Bold", 700);

@include FLOW-get-font("Roboto Condensed", "../fonts/RobotoCondensed-Regular", 300);
@include FLOW-get-font("Roboto Condensed", "../fonts/RobotoCondensed-Bold", 700);

@include FLOW-get-font("Adventor", "../fonts/texgyreadventor-bold", 700);


// -------------------------------------------------------------------
// :: RESPONSIVE TYPOGRAPHY
// -------------------------------------------------------------------
// In case a responsive typographic scale is needed,
// the following approach is suggested
//
// 1) Set font-sizes in _globals.scss
//    $font-size: 16px, 18px, 19px;
//
// 2) Apply scale as needed
//    font-size: rem(nth($font-size, 1));
//    @include FLOW-at($tablet) { font-size: rem(nth($font-size, 2)); }
//    @include FLOW-at($desktop-xl) { font-size: rem(nth($font-size, 3)); }


// -------------------------------------------------------------------
// :: BASE
// -------------------------------------------------------------------

html {
  font-size: rem(16px);
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  @if $debug-mode {
    @include FLOW-at($tablet) { }
    @include FLOW-at($desktop) { }
    @include FLOW-at($desktop-xl) { }
    @include FLOW-at($desktop-xl) { }
  }
}

body {
  color: $font-color;
  font-family: $font-family-text;
  font-size: rem($font-size);
  line-height: $line-height;
  font-weight: 300;
}

h1,h2,h3,h4,h5,h6{
	font-family: $font-family-title;
}

// -------------------------------------------------------------------
// :: NORMALIZE
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h4,
blockquote {
  font-weight: 100;
}

h3,
h5,
h6,
strong {
  font-weight: 700;
}

small {
  display: inline-block;
}


// -------------------------------------------------------------------
// :: MARGINS
// -------------------------------------------------------------------
// If a margin/padding is set somewhere, remove it from below
// Keep it DRY and prevent overwrites

h1,
h2,
h3,
h4,
p,
h5,
h6,
ul,
ol,
body {
  margin: 0;
}
h1 + h4{
	margin-top: 1em;
}

h2 + [class*='button'],
h2 + p{
	margin-top: 2em;
}
h4 + [class*='button']{
	margin-top: 1em;
}
h2 + h4{
	margin-top: 0.7em;
}

// -------------------------------------------------------------------
// :: BLOCK ELEMENTS
// -------------------------------------------------------------------

h1 {
    @include FLOW-at($tablet){
         font-size: rem($font-size-xxxlarge);
    } 
  font-size: rem(45px);
  line-height: 1.025;
  letter-spacing: 2px;
}

h2 {
    @include FLOW-at($tablet){
         font-size: rem($font-size-xxlarge);
    } 
  font-size: rem($font-size-xlarge);
  font-weight: bold;
  line-height: 1.025;
  letter-spacing: 1px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

h3 {
  font-size: rem($font-size-xlarge);
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.45;
  
}

h4 {
  font-size: rem($font-size-large);
  line-height: 1.45;
    color:$secondary-color;
}

h5 {
  font-size: rem($font-size);
  line-height: 1.55;
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  letter-spacing: 1px;
}

h6 {
  line-height: 1.55;
  font-size: rem($font-size-small);
  font-weight: 200;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  sup{
	  font-size:9px;
  }
}

p {
  font-size: $font-size;
  margin: 1.2em 0 1.2em;
  &:first-child{
	  margin: 0;
  }
  [class*="icon"]{
	line-height: 1;
  	&:before{
	  font-size: 2.5em;
	}
  }
}

h6,
small,
cite {
  font-size: rem($font-size-small);
}


h1,h2,h3,h4,h5,h6{
	&.primary-color{
		color: $primary-color;
	}
}



// -------------------------------------------------------------------
// :: DECORATORS
// -------------------------------------------------------------------

a {
  color: $secondary-color;
  text-decoration: none;
  background: transparent;
}

a:hover,
a:active {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  outline: none;
}

a img{
	border: 0;
}

// Make anything look
// like a hyperlink

.is-hyperlink {
  @extend a;
  text-decoration: underline;
  cursor: pointer;
}

// Highlight text-selection
// And adjust font-color for
// deleted/updated content

//mark {
//  background: $ui-yellow;
//}

del {
  color: $font-color-medium;
}

// A better looking horizontal ruler
// Inspired by the HTML5 Boilerplate
// http://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css

hr {
  border: none;
  border-top: 2px solid $medium;
  box-sizing: content-box;
  margin: 1.75em 0;
  //display: block;
  //padding: 0;
  //height: 0;
}

// Blockquote and
// author name (cite)

blockquote {
  font-family: $font-family-text;
  font-weight: 100;
  font-size: $font-size-large;	
  line-height: 1.5;
  color: $secondary-color;
  margin: 0;
}

cite {
  color: $font-color-medium;
  font-family: $font-family-text;
  font-style: normal;
  margin-top: 0.75em;
  display: block;
}

cite:before {
  content: '\2014';
  margin: 0 0.3em;
}

ul li,
ol li{
	margin: 10px 0;
}


.rd-link-more{
	text-decoration: none;
	color: $secondary-color;
	font-size: $font-size-large;
	font-weight: 500;
	position: relative;
	padding-right: 2em;
	span{
		@extend .icon-arrow-right;
		color: $primary-color;
		font-size: 1.8em;
		line-height: 0;
		top: 0px;
		display: inline-block;
		position: absolute;
		right: 0;
		transition:right 0.2s ease-in;
	}
	&:hover{
		color: $primary-color;
		span{
			right: -3px;
		}
	}
}

.padding-height{
  padding: 1rem 0;
}

.margin-height{
  margin: 1rem auto !important;
}

.margin-height-none{
  margin: 0 auto !important;
}

.block{
  display: block;
}


// -------------------------------------------------------------------
// :: ABOUT USING CUSTOM WEB FONTS
// -------------------------------------------------------------------
// Use font-weight & -style attributes for what they're made for
// Avoid creating multiple font-families for different weights and/or
// styles. Keep font declarations DRY and prevent overwrites.
//
// BAD:
//		font-family: "MyCustomFontBold"; 	+	font-weight: normal;
//		font-family: "MyCustomFontItalic"	+	font-style: normal;
//
// GOOD:
// 		font-family: "MyCustomFont"			+	font-weight: 700; (or bold)
//		font-family: "MyCustonFont"			+	font-style: italic;
//
//
// SYNTAX:
// @include FLOW-get-font("FONT-NAME", "PATH/FILE-NAME-WITHOUT-EXTENSION", [font-weight:normal, font-style:normal]);
//
// Example:
// @include FLOW-get-font("MyCustomFont", "../fonts/my-custon-font");
// @include FLOW-get-font("MyCustomFont", "../fonts/my-custon-font", 200, italic);

